import React, { useEffect, useState } from 'react';

import logo from './../../assets/logo-colapse.png';
import logocolapse from './../../assets/logo-colapse.png';

import logoVIROMA from './../../assets/logo-viroma.png';
import logoVIROMAcolapse from './../../assets/logo-viroma.png';
import HeaderRun from './Header.run';
import api from './../../services/api';
import ShowMessages from './../../components/Exceptions';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'react-bootstrap';
import ReactFlagsSelect from 'react-flags-select';
import Swal from 'sweetalert2';
import { phone_format} from "./../../components/Utils";
import ModalMyNumberConnect from '../modals/modal-mynumber-connect';

import {
    IDCOMPANY,
    COMPANY,
    REGISTRATION,
    ReadDataStorage,
    WriteDataStorage,
    getUserData,
    USER_DATA,
    CONTRACTSTATUS,
} from '../../services/auth';
import { subHours } from 'date-fns';


export default function Header({ history, callbackCompany, isAttendance,setIsAttendance }) {
    const [company, setCompany] = useState(0);
    const [dataCompany, setDataCompany] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const [totalAlerts, setTotalAlerts] = useState(0);
    const [language, setLanguage] = useState('BR');
    const [expires_in, setExpires_in] = useState(0);
    const [idStatusContract, setIDStatusContract] = useState(-1);
    const [isAffiliate, setIsAffiliate] = useState(false)
    const [numbers, setNumbers] = useState([])
    const [name, setName] = useState('')
    const [showModalWhatsApp, setShowModalWhatsApp] = useState(false)
    const [data, setData] = useState(false)
    const [isRoot, setIsRoot] = useState(false)
    const [userGroup, setUserGroup] = useState(0)
    const [settings, setSettings] = useState({})

    const elapsedTime = (_datetime) => {
        const agora = subHours(new Date(), 3);
        const diferenca = agora.getTime() - _datetime.getTime();
        const _minutes = Math.floor(diferenca / 1000 / 60);
        const _hours = Math.floor(_minutes / 60);
        const _days = Math.floor(_hours / 24);

        let _message = '';

        if (_days > 0) _message = _message + _days + ' dia(s) ';
        if (_hours % 24 > 0) _message = _message + (_hours % 24) + ' hr(s) ';
        if (_minutes % 60 > 0) _message = _message + (_minutes % 60) + ' min(s) ';
        return _message;
    };

    const Translation = (code) => {
        if (code !== 'BR')
            ShowMessages({
                message: 'Estamos trabalhando na tradução para o idioma selecionado!',
                status: 325,
            });
        setLanguage('BR');
    };

    const setActiveCompany = async (
        value,
        company,
        expires_in = '',
        data = [],
    ) => {
        callbackCompany(value);
        setCompany(value);
        WriteDataStorage(IDCOMPANY, value);
        WriteDataStorage(COMPANY, company);

        let _companies = (dataCompany.length <= 0 ? data : dataCompany).filter(
            (item) => {
                return parseInt(item.value) === parseInt(value);
            },
        );

        let _expires_in = expires_in === '' ? _companies[0].expires_in : expires_in;
        setExpires_in(_expires_in);
        setIsAffiliate(_companies[0].isaffiliate)
        setIDStatusContract(_companies[0].idstatusplan);
        setSettings(_companies[0].settings)
    };

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href="/#"
            ref={ref}
            className="custom-toogle"
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <em
                className="open"
                style={{ fontSize: '14px', marginLeft: '10px' }}
            ></em>
        </a>
    ));

    const importAuthHistoric = async (e) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente importar as 30 últimas mensagens de todos os números autorizados?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await api.post('/getAuthHistory')
                    .then(async (response) => {
                        ShowMessages(response)
                    }).catch(error => {
                        ShowMessages(error)
                    })
            }

        })
    }

    useEffect(() => {
        const StatusWhatsapp = async () => {
            await api
                .get('/getAllMyWhatsAppNumbers')
                .then(async (response) => {
                    setNumbers(response.data)
                  
                    for (const [idx,_row] of (response.data || []).entries()) {
                        await api
                            .post('/api/v1/getStatusNumber', {
                                number: (_row?.number || '').replace(/[\W]+/g, ''),
                                hash: _row.environment,
                            })
                            .then(async (_status) => {
                                if (!_status.data.auth) 
                                    showNotifications(_row.label);
                            })
                            .catch((error) => {
                                ShowMessages(error);
                            });
                    }
                })
                .catch((error) => {
                    ShowMessages(error);
                });
        };

        const getData = async () => {
            setName((await getUserData())['name'])
            await api
                .get('/getcompaniespermission')
                .then((response) => {
                    setDataCompany(response.data);
                    if (response.data) {
                        let _isAdmin = response.data.filter((_item) => {
                            return _item.isadmin === true;
                        });
    
                        if (_isAdmin.length > 0)
                            setActiveCompany(
                                _isAdmin[0].value,
                                _isAdmin[0].label,
                                _isAdmin[0].expires_in,
                                response.data,
                            );
                        else
                            setActiveCompany(
                                response.data[0].value,
                                response.data[0].label,
                                response.data[0].expires_in,
                                response.data,
                            );
                    }
                    getAlerts();
                    setInterval(() => {
                        getAlerts();
                    }, 300000);
                })
                .catch((error) => {
                    ShowMessages(error);
                });
        };

        
        getData();
        HeaderRun();
        getPermissionNumbers()
        //StatusWhatsapp()
        setInterval(() => {
           // StatusWhatsapp();RENATO
            getPermissionNumbers()
        }, 300000);

        if (isNaN(parseInt(ReadDataStorage(CONTRACTSTATUS)))) {
            let _registration = ReadDataStorage(REGISTRATION);
            localStorage.clear();
            WriteDataStorage(REGISTRATION, _registration);
            window.location.href = '/login';
        }

        if ([2,5].includes(parseInt(ReadDataStorage(CONTRACTSTATUS))))
            window.location.href = '/payment';
        setIsRoot(JSON.parse(ReadDataStorage(USER_DATA)).user_data.isroot)
        setUserGroup(JSON.parse(ReadDataStorage(USER_DATA)).user_data.idusergroup)
    }, []);

    useEffect(() => {
        if (isAttendance) 
            toggleCollapsed();
        else
            document.querySelector('body').classList.remove('aside-collapsed');
        
    }, [isAttendance]);

    const switchMode = () => {
        setIsAttendance(!isAttendance)
    }

    const handleCloseModalWhatsapp = () => {setShowModalWhatsApp(false)}

    const toggleCollapsed = (e) => {
        if (e) e.preventDefault();
        document.querySelector('body').classList.toggle('aside-collapsed');
        resize();
    };

    const toggleAside = (e) => {
        if (e) e.preventDefault();
        document.querySelector('body').classList.toggle('aside-toggled');
        resize();
    };

    const toogleChat = (e) => {
        e.preventDefault();
        document.querySelector('body').classList.toggle('offsidebar-open');
    };

    const Logout = async () => {
        Swal.fire({
            title: 'Confirmação',
            text: 'Deseja realmente sair do sistema?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await api
                    .post('/logout')
                    .then(() => {
                        let _registration = ReadDataStorage(REGISTRATION);
                        localStorage.clear();
                        WriteDataStorage(REGISTRATION, _registration);
                        window.location.href = '/login';
                    })
                    .catch((error) => {
                        ShowMessages(error);
                    });
            }
        });
    };

    const getAlerts = async () => {
        await api
            .get('/alerts')
            .then((response) => {
                setAlerts(response.data || []);
                setTotalAlerts(response.data?.length || 0);
            })
            .catch((error) => {
                ShowMessages(error);
            });
    };

    const resize = () => {
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
    };

    const showNotifications = (number) => {
        if (number)
            ShowMessages({status : 500, message : 'O número ' + number + ' está desconectado!'});
    };

    const getStatusNumber = async (number,hash) => {
        setData({
            number,
            environment : hash
        })
        setShowModalWhatsApp(true)
    };

        const getPermissionNumbers = async () => {
        await api
            .get('/getPermissionNumbers')
            .then(async (response) => {
                setNumbers(response.data)
            })
            .catch((error) => {
                ShowMessages(error);
            });
    };

    //   const handleNotificationClick = (event) => {
    //     window.focus();
    //     window.n.close(event.target.tag);
    //   };

    const imgSize = '100%';

    return (
        <header className="topnavbar-wrapper">
            {/* <ReactNotifications
        onRef={(ref) => (window.n = ref)} // Required
        title="55Zap" // Required
        body={message}
        icon={logo}
        tag="abcdef"
        onClick={(event) => handleNotificationClick(event)}
      /> */}
            <ModalMyNumberConnect showModal={showModalWhatsApp} handleCloseModal = {handleCloseModalWhatsapp} data={data}/>
            <nav className="navbar topnavbar">
                <div className="navbar-header only-desktop">
                    <a className="navbar-brand" href="#/">
                        <div className="brand-logo">
                            {window.location.href.indexOf('viromatecnologia.com.br') > 0 ?
                                    <img
                                        className="img-fluid"
                                        src={logoVIROMA}
                                        alt={process.env.REACT_APP_NAME}
                                        style={{ maxWidth: imgSize }}
                                                              />
                                    :
                                    <img
                                        className="img-fluid"
                                        src={logo}
                                        alt={process.env.REACT_APP_NAME}
                                        style={{ maxWidth: imgSize }}
                                />
                            }
  
                        </div>
                        <div className="brand-logo-collapsed">
                            {window.location.href.indexOf('viromatecnologia.com.br') > 0 ?
                                <img
                                    className="img-fluid"
                                    src={logoVIROMAcolapse}
                                    alt={process.env.REACT_APP_NAME}
                                    style={{ maxWidth: "45px" }}
                                />
                            : 
                                <img
                                    className="img-fluid"
                                    src={logocolapse}
                                    alt={process.env.REACT_APP_NAME}
                                    style={{ maxWidth: "45px" }}
                                />
                            }
                        </div>
                    </a>
                </div>
                {!isAttendance ? (
                    <div style={{ flexGrow: 1 }}>
                        <ul className="navbar-nav flex-row only-desktop">
                            <li className="nav-item">
                                <a
                                    href="/#"
                                    className="nav-link d-none d-md-block d-lg-block d-xl-block"
                                    onClick={toggleCollapsed}
                                >
                                    <em className="fas fa-bars"></em>
                                </a>
                                <a
                                    href="/#"
                                    className="nav-link sidebar-toggle d-md-none"
                                    onClick={toggleAside}
                                >
                                    <em className="fas fa-bars"></em>
                                </a>
                            </li>
                        </ul>
                    </div>
                ) :
                    <div style={{ color: "#fff", width: "100%" }}>
                        Olá <span style={{ fontWeight: "bold" }}>{name}</span>, bem vindo ao 55Zap Comunicação Social
                    </div>
                }
                <ul className="navbar-nav mr-auto flex-row only-mobile">
                    {!isAttendance && (
                        <li className="nav-item">
                            <a
                                href="/#"
                                className="nav-link d-none d-md-block d-lg-block d-xl-block"
                                onClick={toggleCollapsed}
                            >
                                <em className="fas fa-bars"></em>
                            </a>
                            <a
                                href="/#"
                                className="nav-link sidebar-toggle d-md-none"
                                onClick={toggleAside}
                            >
                                <em className="fas fa-bars"></em>
                            </a>
                        </li>
                    )}
                    <li className="nav-item ">
                        <Dropdown
                            title="Ajuda & Suporte"
                            className="nav-link"
                            style={{ textAlign: 'right', display: 'flex', marginTop: '-1px' }}
                        >
                            <Dropdown.Toggle as={CustomToggle}>
                                <em className="fa fa-question"></em>
                                <span className="badge badge-warning">1</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ padding: '0px' }}>
                                <Dropdown.Item href="#">
                                    <div
                                        style={{ display: 'flex' }}
                                        onClick={(e) => {
                                            document
                                                .querySelector('.dropdown-menu.show')
                                                .classList.toggle('show');
                                            window.addTab(
                                                e,
                                                'Meus Chamados',
                                                'fa fa-question',
                                                '/helpdesk',
                                                {},
                                                1,
                                                false,
                                            );
                                        }}
                                    >
                                        <div className="fa fa-question alert-primary icon-alert"></div>
                                        <div
                                            className="line-height-condenced ml-3 media-body"
                                            style={{ width: '200px' }}
                                        >
                                            <div style={{ fontWeight: 'bold' }}>Meus Chamados</div>
                                            <div className="small mt-1">12h atrás</div>
                                        </div>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Divider style={{ margin: '0px' }} />
                                <Dropdown.Item href="#">
                                    <div
                                        style={{ display: 'flex' }}
                                        onClick={(e) => {
                                            document
                                                .querySelector('.dropdown-menu.show')
                                                .classList.toggle('show');
                                            window.addTab(
                                                e,
                                                'Notí­cias',
                                                'fa fa-rss',
                                                '/newsletter',
                                                {},
                                                1,
                                                false,
                                            );
                                        }}
                                    >
                                        <div
                                            className="fa fa-rss bg-dark icon-alert"
                                            style={{ color: '#fff', paddingTop: '7px' }}
                                        ></div>
                                        <div
                                            className="line-height-condenced ml-3 media-body"
                                            style={{ width: '200px' }}
                                        >
                                            <div style={{ fontWeight: 'bold' }}>Notícias</div>
                                            <div className="small mt-1">2h atrás</div>
                                        </div>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Divider style={{ margin: '0px' }} />
                                <Dropdown.Item href="#">
                                    <div
                                        style={{ display: 'flex' }}
                                        onClick={(e) => {
                                            document
                                                .querySelector('.dropdown-menu.show')
                                                .classList.toggle('show');
                                            window.addTab(
                                                e,
                                                'O que há de novo?',
                                                'fa fa-newspaper',
                                                '/newsletter-system',
                                                {},
                                                1,
                                                false,
                                            );
                                        }}
                                    >
                                        <div
                                            className="fa fa-newspaper alert-info icon-alert"
                                            style={{
                                                color: '#fff',
                                                paddingLeft: '8px',
                                                fontSize: '26px',
                                            }}
                                        ></div>
                                        <div
                                            className="line-height-condenced ml-3 media-body"
                                            style={{ width: '200px' }}
                                        >
                                            <div style={{ fontWeight: 'bold' }}>
                                                O que há de novo?
                                            </div>
                                            <div className="small mt-1">2h atrás</div>
                                        </div>
                                    </div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                    <li className="nav-item">
                        <Dropdown
                            title="Alertas & Notificações"
                            className="nav-link"
                            style={{ textAlign: 'right', display: 'flex', marginTop: '-1px' }}
                        >
                            <Dropdown.Toggle as={CustomToggle}>
                                {totalAlerts > 0 ? (
                                    <div claclassNamess="notification-box">
                                        <span className="notification-count">{totalAlerts}</span>
                                        <div className="notification-bell">
                                            <span className="bell-top"></span>
                                            <span className="bell-middle"></span>
                                            <span className="bell-bottom"></span>
                                            <span className="bell-rad"></span>
                                        </div>
                                    </div>
                                ) : (
                                    <em className="fa fa-bell"></em>
                                )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ padding: '0px', width: '295px' }}>
                                {totalAlerts > 0 ? (
                                    <div className="alert alert-warning text-center text-white font-weight-bold p-3">
                                        Você tem {totalAlerts} novos alertas!
                                    </div>
                                ) : (
                                    <div className="alert alert-success text-center text-white font-weight-bold p-3">
                                        Você não possui novos alertas!
                                    </div>
                                )}
                                <div
                                    style={{
                                        maxHeight: '265px',
                                        overflow: 'hidden auto',
                                        height: 'auto',
                                    }}
                                >
                                    {alerts.map((item, key) => {
                                        return (
                                            <div key={key}>
                                                <Dropdown.Divider style={{ margin: '0px' }} />
                                                <Dropdown.Item href="#">
                                                    <div
                                                        style={{ display: 'flex' }}
                                                        onClick={(e) => {
                                                            document
                                                                .querySelector('.dropdown-menu.show')
                                                                .classList.toggle('show');
                                                            window.addTab(
                                                                e,
                                                                'Alerta | ' + item.title,
                                                                'fa fa-bell',
                                                                '/alert',
                                                                { id: item.idalert },
                                                                1,
                                                                false,
                                                            );
                                                        }}
                                                    >
                                                        {item.idtype === 1 ? (
                                                            <div
                                                                className="alert alert-success icon-alert"
                                                                style={{
                                                                    color: '#fff',
                                                                    paddingLeft: '9px',
                                                                    paddingTop: '0px',
                                                                }}
                                                            >
                                                                <i className="fa fa-thumbs-up"></i>
                                                            </div>
                                                        ) : item.idtype === 0 ? (
                                                            <div
                                                                className="alert alert-warning icon-alert"
                                                                style={{
                                                                    color: '#fff',
                                                                    paddingLeft: '18px',
                                                                    paddingTop: '1px',
                                                                }}
                                                            >
                                                                <i className="fa fa-exclamation"></i>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className="alert-danger icon-alert"
                                                                style={{
                                                                    color: '#fff',
                                                                    paddingLeft: '7px',
                                                                    paddingTop: '1px',
                                                                }}
                                                            >
                                                                <i className="fa fa-ban"></i>
                                                            </div>
                                                        )}
                                                        <div
                                                            className="line-height-condenced media-body"
                                                            style={{ width: '200px', marginLeft: '15px' }}
                                                        >
                                                            <div style={{ fontWeight: 'bold' }}>
                                                                {item.title}
                                                            </div>
                                                            <div className="small mt-1">
                                                                {elapsedTime(new Date(item.create_at))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Dropdown.Item>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div
                                    className="text-center font-weight-bold p-2 border-top"
                                    style={{ fontSize: '14px' }}
                                    onClick={(e) => {
                                        document
                                            .querySelector('.dropdown-menu.show')
                                            .classList.toggle('show');
                                        window.addTab(
                                            e,
                                            'Todos os Alertas',
                                            'fa fa-bell',
                                            '/alerts-all',
                                            {},
                                            1,
                                            false,
                                        );
                                    }}
                                >
                                    <i className="fa fa-bell" /> Visualizar todos alertas
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                    {!isAttendance && (
                        <li
                            id="select-company"
                            className="nav-item  li-click"
                            style={{ width: '200px', marginTop: '12px' }}
                        >
                            <select
                                name="company"
                                id="company"
                                placeholder="Selecione"
                                value={company}
                                className="form-select"
                                onChange={(e) =>
                                    setActiveCompany(
                                        e.target.value,
                                        e.target.options[e.target.selectedIndex].text,
                                    )
                                }
                            >
                                {dataCompany !== null
                                    ? dataCompany.map((data, id) => {
                                        return (
                                            <option key={id} value={data.value}>
                                                {data.value.toString().padStart(4, '0')} -{' '}
                                                {data.label
                                                    .toString()
                                                    .toUpperCase()
                                                    .substring(0, 50) +
                                                    (data.label.toString().length > 50 ? '...' : '')}
                                            </option>
                                        );
                                    })
                                    : ''}
                            </select>
                        </li>
                    )}
                    <li
                        className="nav-item  li-click"
                        id="btn-sair"
                        style={{ width: '40px' }}
                    >
                        <a
                            href="/"
                            title="Sair do Sistema"
                            onClick={(e) => Logout()}
                            className="nav-link"
                            style={{ textAlign: 'right' }}
                        >
                            <em className="fa fa-times-circle"></em>
                        </a>
                    </li>
                </ul>

                <ul className="navbar-nav flex-row align-items-center only-desktop">
                    {isRoot && (
                        <li
                            className="nav-item"
                            id="div-expire"
                            style={{ display: 'flex', alignItems: 'center', margin: '5px' }}
                        >
                            <div
                                className={`${expires_in <= 2 ? 'bg-danger' : 'bg-primary'}`}
                                style={{
                                    width: '150px',
                                    padding: '5px',
                                    borderRadius: '5px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <span>
                                    {idStatusContract === 0
                                        ? 'Free'
                                        : expires_in === 0
                                            ? 'Vai expirar hoje'
                                            : expires_in < 0
                                                ? `Expirou à ${expires_in * -1} Dia(s)`
                                                : `Expira em ${expires_in} Dia(s)`}
                                </span>
                            </div>
                        </li>
                    )}
                    {[1,2,4,5].includes(userGroup) && (settings?.allowSystemAttendance) && (//ADM,GERAL,SDR,DESIGN CHATBOT
                        <li className="nav-item">
                            <button type='button' onClick={e => switchMode()} title={isAttendance ? 'Mudar para o perfil de gestão' : 'Mudar para o perfil de atendimento'} className="btn btn-success padding0" style={{ marginRight: "10px", width: "40px" }}>
                                <i className='fa fa-repeat'></i>
                            </button>
                        </li>
                    )

                    }

                    {(numbers || []).length > 0 && (
                        <li className="nav-item">
                            <Dropdown group size="sm" style={{ marginRight: "20px", width: "40px" }}>
                                <DropdownToggle variant='warning'>
                                    <i className={'fab fa-whatsapp'} style={{ fontSize: "16px" }} />
                                </DropdownToggle>
                                <DropdownMenu >
                                    {numbers.map((_item, key) => {
                                        return(
                                            <DropdownItem title={_item.idnumberstatus === 0 ? 'Desconectado' : 'Conectado'}>
                                                <div style={{display:"flex", alignItems:"center", gap:"5px", justifyContent:"space-between"}}>
                                                    <div>
                                                        <i className='fa fa-circle' style={{color:`${_item.idnumberstatus === 0 ? '#f05050' : '#198754'}`}}/> 
                                                        {phone_format(_item.number).substr(2,16)} | {_item.description}
                                                    </div>

                                                    {_item.idnumberstatus === 0 && (
                                                        <div title='Tentar reconectar'>
                                                            <button type="button" className='btn btn-success' style={{padding:"2px", width:"32px"}} onClick={e => getStatusNumber(_item.number, _item.environment)}>
                                                                <i className='fa fa fa-globe'/>
                                                            </button>
                                                        </div>  
                                                    )}
                                                </div>

                                            </DropdownItem>
                                        )
                                    })}
                                </DropdownMenu>
                            </Dropdown>
                        </li>
                    )}
                    {isAttendance && (
                        <li className="nav-item">
                            <button type='button' onClick={e => importAuthHistoric()} title='Importar histórico de todos os números com acesso' className="btn btn-purple padding0" style={{ marginRight: "10px", width: "40px" }}>
                                <i className='fa fa-comments'></i>
                            </button>
                        </li>
                    )}
                    <li className="nav-item">
                        <button title={`${isAffiliate ? 'Link de Afiliado' : 'Afiliar-se'}`}
                            className="btn btn-success  d-flex align-items-center" style={{ paddingRight: "3px", width: `${isAffiliate ? '40px' : '95px'}` }}
                            onClick={(e) => {
                                window.addTab(
                                    e,
                                    isAffiliate ? 'Link de Afiliado' : 'Afiliar-se',
                                    'fa fa-handshake',
                                    '/afiliate',
                                    {},
                                    1,
                                    true,
                                );
                            }}
                        >
                            <i
                                className={`fa ${isAffiliate ? 'fa-link' : 'fa-handshake'}`}
                            ></i>
                            <span>&nbsp; {isAffiliate ? '' : 'Afiliar-se'}</span>
                        </button>
                    </li>

                    <li className="nav-item d-none d-md-block li-click " id="btn-news">
                        <Dropdown
                            title="Ajuda & Suporte"
                            className="nav-link"
                            style={{ textAlign: 'right', display: 'flex', marginTop: '-1px' }}
                        >
                            <Dropdown.Toggle as={CustomToggle}>
                                <em className="fa fa-question"></em>
                                {/* <span className="badge badge-warning">1</span>  */}
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ padding: '0px' }}>
                                <Dropdown.Item href="#">
                                    <div
                                        style={{ display: 'flex' }}
                                        onClick={(e) => {
                                            document
                                                .querySelector('.dropdown-menu.show')
                                                .classList.toggle('show');
                                            window.addTab(
                                                e,
                                                'Meus Chamados',
                                                'fa fa-question',
                                                '/helpdesk',
                                                {},
                                                1,
                                                false,
                                            );
                                        }}
                                    >
                                        <div className="fa fa-question alert-primary icon-alert"></div>
                                        <div
                                            className="line-height-condenced ml-3 media-body"
                                            style={{ width: '200px' }}
                                        >
                                            <div style={{ fontWeight: 'bold' }}>Meus Chamados</div>
                                            <div className="small mt-1">12h atrás</div>
                                        </div>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Divider style={{ margin: '0px' }} />
                                <Dropdown.Item href="#">
                                    <div
                                        style={{ display: 'flex' }}
                                        onClick={(e) => {
                                            document
                                                .querySelector('.dropdown-menu.show')
                                                .classList.toggle('show');
                                            window.addTab(
                                                e,
                                                'Notí­cias',
                                                'fa fa-rss',
                                                '/newsletter',
                                                {},
                                                1,
                                                false,
                                            );
                                        }}
                                    >
                                        <div
                                            className="fa fa-rss bg-dark icon-alert"
                                            style={{ color: '#fff', paddingTop: '7px' }}
                                        ></div>
                                        <div
                                            className="line-height-condenced ml-3 media-body"
                                            style={{ width: '200px' }}
                                        >
                                            <div style={{ fontWeight: 'bold' }}>Notí­cias</div>
                                            <div className="small mt-1">2h atrás</div>
                                        </div>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Divider style={{ margin: '0px' }} />
                                <Dropdown.Item href="#">
                                    <div
                                        style={{ display: 'flex' }}
                                        onClick={(e) => {
                                            document
                                                .querySelector('.dropdown-menu.show')
                                                .classList.toggle('show');
                                            window.addTab(
                                                e,
                                                'O que há de novo?',
                                                'fa fa-newspaper',
                                                '/newsletter-system',
                                                {},
                                                1,
                                                false,
                                            );
                                        }}
                                    >
                                        <div
                                            className="fa fa-newspaper alert-info icon-alert"
                                            style={{
                                                color: '#fff',
                                                paddingLeft: '8px',
                                                fontSize: '26px',
                                            }}
                                        ></div>
                                        <div
                                            className="line-height-condenced ml-3 media-body"
                                            style={{ width: '200px' }}
                                        >
                                            <div style={{ fontWeight: 'bold' }}>
                                                O que há de novo?
                                            </div>
                                            <div className="small mt-1">2h atrás</div>
                                        </div>
                                    </div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                    <li className="nav-item d-none d-md-block li-click " id="btn-alert">
                        <Dropdown
                            title="Alertas & Notificações"
                            className="nav-link"
                            style={{ textAlign: 'right', display: 'flex', marginTop: '-1px' }}
                        >
                            <Dropdown.Toggle as={CustomToggle}>
                                {totalAlerts > 0 ? (
                                    <div className="notification-box">
                                        <span className="notification-count">{totalAlerts}</span>
                                        <div className="notification-bell">
                                            <span className="bell-top"></span>
                                            <span className="bell-middle"></span>
                                            <span className="bell-bottom"></span>
                                            <span className="bell-rad"></span>
                                        </div>
                                    </div>
                                ) : (
                                    <em className="fa fa-bell"></em>
                                )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ padding: '0px', width: '295px' }}>
                                {totalAlerts > 0 ? (
                                    <div className="alert alert-warning text-center text-white font-weight-bold p-3">
                                        Você tem {totalAlerts} novos alertas!
                                    </div>
                                ) : (
                                    <div className="alert alert-success text-center text-white font-weight-bold p-3">
                                        Você não possui novos alertas!
                                    </div>
                                )}
                                <div
                                    style={{
                                        maxHeight: '265px',
                                        overflow: 'hidden auto',
                                        height: 'auto',
                                    }}
                                >
                                    {alerts.map((item, key) => {
                                        return (
                                            <div key={key}>
                                                <Dropdown.Divider style={{ margin: '0px' }} />
                                                <Dropdown.Item href="#">
                                                    <div
                                                        style={{ display: 'flex' }}
                                                        onClick={(e) => {
                                                            document
                                                                .querySelector('.dropdown-menu.show')
                                                                .classList.toggle('show');
                                                            window.addTab(
                                                                e,
                                                                'Alerta | ' + item.title,
                                                                'fa fa-bell',
                                                                '/alert',
                                                                { id: item.idalert },
                                                                1,
                                                                false,
                                                            );
                                                        }}
                                                    >
                                                        {item.idtype === 1 ? (
                                                            <div
                                                                className="alert alert-success icon-alert"
                                                                style={{
                                                                    color: '#fff',
                                                                    paddingLeft: '9px',
                                                                    paddingTop: '0px',
                                                                }}
                                                            >
                                                                <i className="fa fa-thumbs-up"></i>
                                                            </div>
                                                        ) : item.idtype === 0 ? (
                                                            <div
                                                                className="alert alert-warning icon-alert"
                                                                style={{
                                                                    color: '#fff',
                                                                    paddingLeft: '18px',
                                                                    paddingTop: '1px',
                                                                }}
                                                            >
                                                                <i className="fa fa-exclamation"></i>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className="alert-danger icon-alert"
                                                                style={{
                                                                    color: '#fff',
                                                                    paddingLeft: '7px',
                                                                    paddingTop: '1px',
                                                                }}
                                                            >
                                                                <i className="fa fa-ban"></i>
                                                            </div>
                                                        )}
                                                        <div
                                                            className="line-height-condenced media-body"
                                                            style={{ width: '200px', marginLeft: '15px' }}
                                                        >
                                                            <div style={{ fontWeight: 'bold' }}>
                                                                {item.title}
                                                            </div>
                                                            <div className="small mt-1">
                                                                {elapsedTime(new Date(item.create_at))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Dropdown.Item>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div
                                    className="text-center font-weight-bold p-2 border-top"
                                    style={{ fontSize: '14px' }}
                                    onClick={(e) => {
                                        document
                                            .querySelector('.dropdown-menu.show')
                                            .classList.toggle('show');
                                        window.addTab(
                                            e,
                                            'Todos os Alertas',
                                            'fa fa-bell',
                                            '/alerts-all',
                                            {},
                                            1,
                                            false,
                                        );
                                    }}
                                >
                                    <i className="fa fa-bell" /> Visualizar todos alertas
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                    {!isAttendance && (
                        <>
                            <li
                                className="nav-item li-click li-company-title"
                                id="btn-company"
                                title="Selecione a empresa"
                            >
                                <select
                                    name="company"
                                    id="company"
                                    placeholder="Selecione"
                                    value={company}
                                    className="form-select"
                                    onChange={(e) =>
                                        setActiveCompany(
                                            e.target.value,
                                            e.target.options[e.target.selectedIndex].text,
                                        )
                                    }
                                >
                                    {dataCompany !== null
                                        ? dataCompany.map((data, id) => {
                                            return (
                                                <option key={id} value={data.value}>
                                                    {data.value.toString().padStart(4, '0')} -{' '}
                                                    {data.label
                                                        .toString()
                                                        .toUpperCase()
                                                        .substring(0, 50) +
                                                        (data.label.toString().length > 50 ? '...' : '')}
                                                </option>
                                            );
                                        })
                                        : ''}
                                </select>
                            </li>
                            <li
                                className="nav-item li-click"
                                id="btn-language"
                                style={{
                                    marginLeft: '15px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                title="Selecione o idioma do sistema"
                            >
                                <ReactFlagsSelect
                                    className="containt-combo-flag "
                                    selectButtonClassName="combo-flag"
                                    showSelectedLabel={false}
                                    showOptionLabel={false}
                                    selected={language}
                                    onSelect={(code) => Translation(code)}
                                    countries={[
                                        'BR',
                                        'US',
                                        'IN',
                                        'ID',
                                        'MX',
                                        'RU',
                                        'IT',
                                        'DE',
                                        'ES',
                                        'FR',
                                    ]}
                                />
                            </li>
                        </>
                    )}
                    {process.env.REACT_APP_ENABLED_CHAT === true ? (
                        <li
                            className="nav-item d-none d-md-block li-click"
                            style={{ width: 'auto' }}
                        >
                            <a
                                title="Chat"
                                href="#"
                                className="nav-link"
                                style={{
                                    textAlign: 'right',
                                    display: 'flex',
                                    marginTop: '3px',
                                }}
                                onClick={toogleChat}
                            >
                                <em className="fa fa-comments"></em>
                                <span className="badge badge-danger">1</span>
                            </a>
                        </li>
                    ) : (
                        ''
                    )}
                    <li className="nav-item d-none d-md-block li-click " id="btn-sair1">
                        <a
                            title="Sair do Sistema"
                            onClick={(e) => Logout()}
                            className="nav-link"
                        >
                            <em className="fa fa-times-circle"></em>
                        </a>
                    </li>
                </ul>
            </nav>
        </header>
    );
}
