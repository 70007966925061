import React, {useState, useEffect} from 'react'

import { Button} from 'reactstrap';
import Draggable from 'react-draggable';

import MessageView from '../../components/messageView/messageview';
import { Modal, ModalDialog } from 'react-bootstrap';
import Loading from '../../components/Loading';
import setStatistic from '../../services/apiStatistic'


class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalAutomationHistory({showModal, handleCloseModal, data}) {
    const [loading, setLoading]         = useState(false)
    

    useEffect(() => {
        setStatistic('modals\\modal-automation-history')
    },[])

    return (
      
        <Modal size='mf' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogClassName="height500" dialogAs={DraggableModalDialog}>
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-robot"></i> Histórico de Atendimento | Protocolo: #{data.idautomationchatheadhistory}
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    {loading && (
                        <Loading loading={loading} addClass="heigth330px"/>
                    )}
                    <div className={`${loading ? "hidden" : ""}`}>               
                        <div style={{display:"flex", flexDirection:"column", height:"340px", overflowY:"auto"}}>
                            <MessageView viewMode={true}/>
                        </div>        
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
        </Modal>
       
    )
}