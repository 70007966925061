import React, {useState, useEffect} from 'react'

import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Draggable from 'react-draggable';
import Swal from 'sweetalert2'
import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import ModalLeadTransferTags from '../modals/modal-lead-transfer-tags';
import ModalLeadSubdivideFlag from './admin/modal-plan';

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalLeadMaintenance({showModal, handleCloseModal, handleReload, data}) {
    const [showModalTransfer, setShowModalTransfer]           = useState(false)
    const [showModalSubdivideFlag, setShowModalSubdivideFlag] = useState(false)
    const [loadingAll, setLoadingAll]                         = useState(false)
    const [loadingTag, setLoadingTag]                         = useState(false)
    const [loadingNumber, setLoadingNumber]                   = useState(false)
    const [loadingUpdate, setLoadingUpdate]                   = useState(false)

    const handleTransferContactForTag = async () => {
        setShowModalTransfer(true)
    }

    const handleUpdateName = async(event) => {
        event.preventDefault()
        Swal.fire({
            title: 'Confirmação',
            text: `Deseja realmente atualizar os nomes em branco?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                setLoadingUpdate(true)
                await api.post('/getUpdateNameInWatsApp')
                             .then(response => {
                                  setLoadingUpdate(false)
                                  handleCloseModal(true)
                                  handleReload(true)
                                  ShowMessages(response)
                               })
                               .catch( error => {
                                  ShowMessages(error) 
                               })
            }
            
        }) 
    }

    const handleRepeated = async(event,param) => {
        
        event.preventDefault()
        Swal.fire({
            title: 'Confirmação',
            text: `Deseja realmente excluir todos os registro repetidos${param === 0 ? ' da mesma tag' : ''}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                if (parseInt(param) === 0)
                    setLoadingTag(true)
                else
                    setLoadingAll(true) 

                try {
                    await api.post('/delete/repetead',{param})
                             .then(response => {
                                  ShowMessages(response)
                                  if (response.ok) {   
                                     if (response.data.status === 200) {
                                         handleCloseModal()  
                                         handleReload(true)    
                                     }   
                                  }
                                  
                                  if (parseInt(param) === 0)
                                      setLoadingTag(false)
                                  else
                                      setLoadingAll(false)   
                               })
                               .catch( error => {
                                  if (parseInt(param) === 0)
                                      setLoadingTag(false)
                                  else
                                      setLoadingAll(false) 
                                  ShowMessages(error) 
                               })
                } catch (response) {
                    if (parseInt(param) === 0)
                        setLoadingTag(false)
                    else
                        setLoadingAll(false)   
                    ShowMessages(response)
                }
            }
            
        }) 
    }

    const handleExportNumberIsBlank = async(e) => {
        setLoadingNumber(true) 
        await api.post('/exportNotNumber')
                 .then(response => {
                    ShowMessages({status : 200, message : "Arquivo exportado com sucesso!"}) 
                    var link = document.createElement('a');
                    link.download = 'contatos-sem-numero.xlsx';
                    link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response.data;
                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                    setLoadingNumber(false) 
                 })
                 .catch( error => {
                    ShowMessages(error) 
                    setLoadingNumber(false) 
                  })
    }

    const handleTransferFlags = async(e) => {
        setShowModalSubdivideFlag(true)
    }
    
    useEffect(() => {

    },[showModal])
   
    useEffect(() => {
        setStatistic('modals\\modal-lead-maintenance')
    } ,[])

    const handleCloseModalTransfer = () => {
        setShowModalTransfer(false)
    }

    const handleCloseModalSubdivideFlag = () => {
        setShowModalSubdivideFlag(false)
    }
    

    return (
        <>
            {showModalTransfer && <ModalLeadTransferTags showModal={showModalTransfer} handleCloseModal = {handleCloseModalTransfer} />}
            {showModalSubdivideFlag && <ModalLeadSubdivideFlag showModal={showModalSubdivideFlag} handleCloseModal = {handleCloseModalSubdivideFlag} />}

            <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-cogs"></i> Manutenção de Contatos
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className='box-shadow'>
                        <div style={{display:"flex", gap:"15px"}}>  
                            <div style={{width:"150px", height:"100px"}}>
                                <button className="btn btn-primary" style={{height:"auto"}} onClick={e => handleRepeated(e,0)} disabled={loadingTag || loadingAll || loadingNumber || loadingUpdate}>
                                    <div style={{display:"flex", alignItems:"center", flexDirection:"column", marginTop:"15px"}}>
                                        <i className={`fa fa-lg ${loadingTag ? 'fa-spinner fa-spin' : 'fa-users-slash'}`}></i> 
                                        <span style={{marginTop:"15px"}}>Remover contatos repetidos COM mesma TAG</span>
                                    </div> 
                                </button>
                            </div>

                            <div style={{width:"150px", height:"100px"}}>
                                <button className="btn btn-warning" style={{height:"auto"}} onClick={e => handleRepeated(e,1)} disabled={loadingTag || loadingAll || loadingNumber || loadingUpdate}>
                                    <div style={{display:"flex", alignItems:"center", flexDirection:"column", marginTop:"15px", color:"#000"}} >
                                        <i className={`fa fa-lg ${loadingAll ? 'fa-spinner fa-spin' : 'fa-users-rays'}`}></i> 
                                        <span style={{marginTop:"15px"}}>Remover contatos repetidos de TODAS as TAGS</span>
                                    </div> 
                                </button>
                            </div> 

                            <div style={{width:"150px", height:"100px"}}>
                                <button className="btn btn-success" style={{height:"auto"}} onClick={e => handleExportNumberIsBlank(e)} disabled={loadingTag || loadingAll || loadingNumber || loadingUpdate}>
                                    <div style={{display:"flex", alignItems:"center", flexDirection:"column", marginTop:"15px", color:"#000"}} >
                                        <i className={`fa fa-lg ${loadingNumber ? 'fa-spinner fa-spin' : 'fa-phone-slash'}`}></i> 
                                        <span style={{marginTop:"15px"}}>Exportar lista de contatos sem número</span>
                                    </div> 
                                </button>
                            </div>  
                        </div>      
                        <div style={{display:"flex", marginTop:"17px", gap:"15px"}}>
                            <div style={{width:"147px", height:"100px", display:"flex"}}>
                                <button className="btn btn-secondary" style={{height:"auto"}} onClick={e => handleTransferContactForTag(e)} disabled={loadingTag || loadingAll || loadingNumber || loadingUpdate}>
                                    <div style={{display:"flex", alignItems:"center", flexDirection:"column", marginTop:"15px", color:"#000"}} >
                                        <i className={`fa fa-lg ${loadingAll ? 'fa-spinner fa-spin' : 'fa-right-left'}`}></i> 
                                        <span style={{marginTop:"15px"}}>Transferência de Contatos por TAG</span>
                                    </div> 
                                </button>
                            </div>  
                            <div style={{width:"150px", height:"100px", display:"flex"}}>
                                <button className="btn btn-theme" style={{height:"auto"}} onClick={e => handleTransferFlags(true)} disabled={loadingTag || loadingAll || loadingNumber || loadingUpdate}>
                                    <div style={{display:"flex", alignItems:"center", flexDirection:"column", marginTop:"15px", color:"#fff"}} >
                                        <i className={`fa fa-lg ${loadingAll ? 'fa-spinner fa-spin' : 'fa-flag'}`}></i> 
                                        <span style={{marginTop:"15px"}}>Subdividir TAG por estado</span>
                                    </div> 
                                </button>
                            </div>    
                            <div style={{width:"150px", height:"100px"}}>
                                <button className="btn btn-info" style={{height:"auto"}} onClick={e => handleUpdateName(e)} disabled={loadingTag || loadingAll || loadingNumber || loadingUpdate}>
                                    <div style={{display:"flex", alignItems:"center", flexDirection:"column", marginTop:"15px", color:"#fff"}} >
                                        <i className={`fa-lg  ${loadingUpdate ? 'fa fa-spinner fa-spin' : 'fab fa-whatsapp'}`}></i> 
                                        <span style={{marginTop:"15px"}}>Buscar Nomes dos Leads no WhatsApp</span>
                                    </div> 
                                </button>
                            </div>  
                        </div>

                    </div>

                </Modal.Body>
                <Modal.Footer>  
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}