import React, { useEffect, useState } from "react";

import { Accordion, Alert, Button, Modal, ModalDialog } from "react-bootstrap";
import { TextInput, ValidationForm } from "react-bootstrap4-form-validation";
import Draggable from "react-draggable";
import { useReactFlow } from "reactflow";
import ShowMessages from "../../../components/Exceptions";
import api from '../../../services/api';
import { TagsInput } from "react-tag-input-component";

class DraggableModalDialog extends React.Component {
    render() {
        return (
            <Draggable handle=".modal-header">
                <ModalDialog {...this.props} />
            </Draggable>
        );
    }
}

const activeForMessageOptions = [
    {
        label: "Iniciar por Campanha, WebHook ou Gatilho",
        value: 0,
    },
    {
        label: "Aguardar Contato",
        value: 1,
    },
    {
        label: "Agendamento",
        value: 2,
    },
    {
        label: "Notificações de Grupo(s)",
        value: 3,
    },
];

const triggerOptions = [
    {
        label: "Qualquer palavra",
        value: 0,
    },
    {
        label: "Palavras específicas",
        value: 1,
    },
];

const activationOptions = [
    {
        label: "Dia da Semana",
        value: 0,
    },
    {
        label: "Dia da Específico",
        value: 1,
    },
    {
        label: "Inatividade do Atendente",
        value: 2,
    },
];

const eventOption = [
    {
        label : "Selecione",
        value : -1
    },
    {
        label : "Entrada do Usuário no Grupo",
        value : 0
    },
    {
        label : "Saída do Usuário no Grupo",
        value : 1
    }
]

export default function ModalNodeStart({
    nodeId,
    showModal,
    handleCloseModal,
    nodeData,
}) {

    const [activeForMessage, setActiveForMessage] = useState(1);
    const [triggerType, setTriggerType]           = useState(0);
    const [messagesActive, setMessagesActive]     = useState([]);
    const [triggerWord, setTriggerWord]           = useState("");
    const [commercial, setCommercial]             = useState(false)
    const [nonCommercial, setNonCommercial]       = useState(false)
    const [name, setName]                         = useState('')
    const [triggerContent, setTriggerContent]     = useState(0)
    const [number, setNumber]                     = useState('')
    const [phoneData, setPhoneData]               = useState([])
    const [selectedTags, setSelectTags]           = useState([])
    const [tag, setTag]                           = useState('')
    const [dataTags, setDataTags]                 = useState([])
    const [chatData, setChatData]                 = useState([])
    const [activateOption, setActivateOption]     = useState(0)
    const [hours, setHours]                       = useState(null)
    const [day, setDay]                           = useState(0)
    const [dayWeek, setDayWeek]                   = useState(null)
    const [count, setCount]                       = useState(0)
    const [limitLeads, setLimitLeads]             = useState(0)
    const [chatbots, setChatbots]                 = useState([])

    const [eventGroup, setEventGroup]             = useState(-1)
    const [group, setGroup]                       = useState(null)
    const [groupName, setGroupName]               = useState('')
    const [dataGroups,setDataGroups]              = useState([])
    const [groups, setGroups]                     = useState([])
    const [idautomationchat, setIdAutomationChat] = useState(-1)

    const reactFlowInstance = useReactFlow();

    const addChatbot = () => {
        const _chatbotList = chatbots.some((item) => {
            if (item.idautomationchat === idautomationchat) {
                return true;
            }
            return false;
        });

        if (idautomationchat === -1) {
            ShowMessages({ status: 500, message: "ChatBot não informado" });
            return;
        }

        if (_chatbotList) {
            ShowMessages({ status: 500, message: "Chatbot já informado" });
            return;
        }

        setChatbots([...chatbots, { idautomationchat,  description : chatData.filter(_item => _item.idautomationchat === idautomationchat)[0].title}]);
        setIdAutomationChat(-1)
    };

    const removeChatBot = (_automationchat) => {
        let _chatbots = chatbots.filter((item) => {
            return item.idautomationchat !== _automationchat;
        });

        setChatbots(_chatbots);
    };

    const addMessageActive = () => {
        const wordAlreadyExistInArrayTWords = messagesActive.some((item) => {
            if (item.triggerWord === triggerWord) {
                return true;
            }
            return false;
        });

        if (triggerWord.length === 0) {
            ShowMessages({ status: 500, message: "Palavra gatilho não informada" });
            return;
        }
        if (messagesActive.length >= 50) {
            ShowMessages({
                status: 500,
                message: "Permitido apenas 50 Palavras gatilho",
            });
            return;
        }

        if (wordAlreadyExistInArrayTWords) {
            ShowMessages({ status: 500, message: "Palavra gatilho já existe" });
            return;
        }

        setMessagesActive([...messagesActive, { triggerWord: triggerWord,  triggerContent}]);
        setTriggerWord('')
    };

    const removeMessageActive = (_word) => {
        let arrayOfMessageActive = messagesActive.filter((item) => {
            return item.triggerWord !== _word;
        });

        setMessagesActive(arrayOfMessageActive);
    };
    
    const handleGroup = (id, value) => {
        setGroup(id)
        setGroupName(value)
    }

    const addGroup = (e) => {
        if (group === -1) {
            ShowMessages({ status: 500, message: "Selecione um grupo antes de incluir!" });
            return; 
        }

        const checkgroup = groups.some((item) => {
            if (item.chatId === group) {
                return true;
            }
            return false;
        });

        if (groups.length >= 5) {
            ShowMessages({
                status: 500,
                message: "Permitido apenas 5 grupos por fluxo",
            });
            return;
        }

        if (checkgroup) {
            ShowMessages({ status: 500, message: "O grupo informado já está incluído!" });
            return;
        }

        setGroups([...groups, { chatId: group, chat : groupName}]);
        setGroup(-1)
    };

    const removeGroup = (_group) => {
        let arrayOfGroup = groups.filter((item) => {
            return item.chatId !== _group;
        });

        setGroups(arrayOfGroup);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (number === '') {
            ShowMessages({status : 500, message : "Informe o número desejado"})
            return
        }

        if ((eventGroup === -1) && (activeForMessage === 3)) {
            ShowMessages({status : 500, message : "Selecione o evento desejado"})
            return
        }
     
        if (((hours === '') || (hours === 0) || (hours === '00:00')) && (activeForMessage === 2))  {
            ShowMessages({status : 500, message : "Hora não informada"}) 
            return
        }


        if ((chatbots.length <= 0) && (activeForMessage === 2) && (activateOption === 2)) {
            ShowMessages({status : 500, message : "Você não informou nenhum chatbot"}) 
            return
        }

        if ((((hours || '') === '') || (hours === 0) || (hours === '00:00')) && (activeForMessage === 2) && (activateOption === 2)) {
            ShowMessages({status : 500, message : '"por" não informado'}) 
            return
        }
        
        reactFlowInstance.setNodes((nds) =>
            nds.map((node) => {
                if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        nodeInfo: {
                            node             : 0,
                            isStart          : true,
                            objectType       : 0,
                            targetId         : nodeData.targetId,
                            triggerContent,
                            triggerType,
                            nonCommercial : activeForMessage === 1 ? nonCommercial : false,
                            commercial : activeForMessage === 1 ? commercial : false,
                            messagesActive,
                            name,
                            selectedTags,
                            number ,
                            chatbots,
                            activeForMessage,
                            activateOption,
                            limitLeads,
                            hours,
                            day,
                            dayWeek,
                            count,
                            eventGroup,
                            groups
                        },
                    };
                }
                return node;
            })
        );
        handleCloseModal();
    };

    const handleTagInput = (value) => { 
        value = value.map(function(x){ return x.toString().toUpperCase().replace(/[^a-zA-Z0-9_]/g,''); })     
        
        if (value === '')
            return false    
        
        if (selectedTags.length !== value.length)
            setSelectTags(value)
    }

    const addValidationTag = (_tag) => {
        if (_tag.trim() === '')
            return false
        _tag = _tag.toString().toUpperCase()
        return !selectedTags.includes(_tag)
    }

    const getDataTags = async() => {
        try {
            await api.get('/getDataCampaing')
                    .then(response => { 
                        setDataTags(response.data.tags)
                    })
                    .catch( error => {      
                        ShowMessages(error) 
                    })
        } catch (response) { 
            ShowMessages(response)
        }
    }

    const getGroups = async() => {
        if ((number !== "") && (activeForMessage === 3)) {
            try {
                await api.get('/leads/groups/' + number)
                        .then(response => {
                            setDataGroups(response.data.data || []) 
                        })
                        .catch( error => {      
                            ShowMessages(error) 
                        })
            } catch (response) { 
                console.log(response) 
                ShowMessages(response)
            }
        }
    }

    useEffect(() => {
        setActiveForMessage(nodeData.activeForMessage)
        setTriggerType(nodeData.triggerType)
        setMessagesActive(nodeData.messagesActive)
        setTriggerWord("")
        setCommercial(nodeData.commercial)
        setNonCommercial(nodeData.nonCommercial)
        setName(nodeData.name)
        setTriggerContent(nodeData.triggerContent || 0)
        setNumber(nodeData?.number || '')
        setSelectTags(nodeData?.selectedTags || [])
        setActivateOption(nodeData?.activateOption)
        setHours(nodeData?.hours || null)
        setDayWeek(nodeData?.dayWeek)
        setCount(nodeData?.count)
        setLimitLeads(nodeData?.limitLeads)
        setDay(nodeData?.day)
        setEventGroup(nodeData?.eventGroup)
        setChatbots(nodeData?.chatbots || [])
        setGroups(nodeData?.groups || [])
    }, [nodeData]);

    const getData = async() => {
        try {
            await api.get('/getAllMyWhatsAppNumbers')
                    .then(response => {
                        setPhoneData(response.data)     
                    })
                    .catch( error => {      
                        ShowMessages(error) 
                    })
        } catch (response) { 
            ShowMessages(response)
        }

        await api.get('/getChatBotGroup')
                 .then(async response2 => {
                    setChatData(response2.data)
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    useEffect(() => {
        getData()
        getDataTags()
    }, []);

    useEffect(() => {
        getGroups()
    }, [number, activeForMessage]);

    useEffect(() => {
        if (nonCommercial && commercial) {
            setCommercial(false)
            setNonCommercial(false)
        }
    }, [nonCommercial, commercial]);
    

    return (
        <Modal
            size="lg"
            onSubmit={handleSubmit}
            show={showModal}
            onHide={handleCloseModal}
            dialogClassName="maxHeight450 width600"
            animation={true}
            backdrop="static"
            dialogAs={DraggableModalDialog}
        >
     
        <ValidationForm className="modal-content" setFocusOnError={true}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <i className="fa fa-share-nodes"></i> Inicio do Fluxo
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="box-shadow" style={{padding:"10px"}}>
                    <div style={{display:"flex", gap:"5px"}}>
                        <div style={{width:"100%"}}>
                            <label>Nome do Fluxo</label>
                            <label className="text-required">*</label>
                            <div className="input-group w-100">
                                <TextInput type='text' required className="form-control" value={name} onChange={e => setName(e.target.value)} errorMessage={{ required: "Por favor, informe um Nome!" }}/>
                            </div>
                        </div>
                        <div style={{width:"280px"}}>
                            <span>Número</span><label className="text-required">*</label>
                            <select className="form-select" value={number} onChange={e => setNumber(e.target.value)} >
                                <option value="">Selecione</option>
                                {phoneData.map((item, key) => {
                                    return(<option key={key} value={item.number}>{item.phone_format}</option>)
                                })}
                            </select>
                        </div>
                    </div>
                    
                    <div style={{display:"flex", marginBottom:"5px",flexDirection:"column"}}>
                        <div style={{display:"flex", gap:"15px"}}>
                            <div style={{width: "200px", minWidth:"200px"}} >
                                <label>Tipo do fluxo</label>
                                <div className="input-group w-100">
                                    <select
                                        name="activeForMessage"
                                        id="activeForMessage"
                                        className="form-select w-100"
                                        placeholder="Selecione"
                                        onChange={(e) => setActiveForMessage(parseInt(e.target.value))}
                                        value={activeForMessage}
                                    >
                                        {activeForMessageOptions.map((data, id) => {
                                            return (
                                                <option key={id} value={data.value}>
                                                    {data.label}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
         
                            {(activeForMessage === 1) && (
                                <div>
                                    <div style={{display:"flex",background:"transparent", width:"fit-content"}}>
                                        <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                            <input type="checkbox" checked={commercial} value={commercial} onClick={e => setCommercial(!commercial)}  />
                                            <span style={{ marginTop: "5px" }}></span>
                                        </label>
                                        <span style={{marginLeft:"5px",marginTop:"5px"}}>Ativar somente em horário comercial?</span>
                                    </div>
                                    <div style={{display:"flex",background:"transparent", width:"fit-content"}}>
                                        <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                            <input type="checkbox" checked={nonCommercial} value={nonCommercial} onClick={e => setNonCommercial(!nonCommercial)}  />
                                            <span style={{ marginTop: "5px" }}></span>
                                        </label>
                                        <span style={{marginLeft:"5px",marginTop:"5px"}}>Ativar somente fora do horário comercial?</span>
                                    </div>    
                                                       
                                </div>
                            )}
                            {(activeForMessage === 2) && (
                                    <div style={{display:"flex", gap:"5px"}}>
                                        <div style={{ width: "145px" }} title="A cada vez que o fluxo e iniciado, o contador e incrementado, e pode ser usado dentro do fluxo usando o parâmetro [CONTADOR]">
                                            <label>Contador</label><i className="fa fa-circle-question" style={{ marginLeft: "5px", color: "#0d6efd" }} />
                                            <TextInput
                                                name="count"
                                                id="count"
                                                type="number"
                                                autoFocus={true}
                                                value={count}
                                                className="form-control "
                                                onChange={(e) => setCount(e.target.value)}
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div style={{ width: "145px" }} title="Essa configuração é usada para limitar a quantidade máxima de leads a ser enviados no agendamento. EX: a TAG CLIENTES tem 500 leads, mas você gostaria de iniciar o fluxo apenas para 100 leads desses 500, basta setar 100 na configuração que apenas os 100 primeiros leads serão carregados para o envio.">
                                            <label>Limitador de Leads</label><i className="fa fa-circle-question" style={{ marginLeft: "5px", color: "#0d6efd" }} />
                                            <TextInput
                                                name="count"
                                                id="count"
                                                type="number"
                                                autoFocus={true}
                                                value={limitLeads}
                                                className="form-control "
                                                onChange={(e) => setLimitLeads(e.target.value)}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                            )}
                            {(activeForMessage === 3) && (
                                <div style={{display:"flex", gap:"15px"}}>
                                    <div style={{width: "215px"}}>
                                        <label>Tipo de Evento</label><label className="text-required">*</label>
                                        <div className="input-group w-100">
                                            <select
                                                name="eventGroup"
                                                id="eventGroup"
                                                className="form-select w-100"
                                                placeholder="Selecione"
                                                onChange={(e) => setEventGroup(parseInt(e.target.value))}
                                                value={eventGroup}
                                            >
                                                {eventOption.map((data, id) => {
                                                    return (
                                                        <option key={id} value={data.value}>
                                                            {data.label}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div style={{width:"100px"}} title="A cada vez que o fluxo e iniciado, o contador e incrementado, e pode ser usado dentro do fluxo usando o parâmetro [CONTADOR]">
                                        <label>Contador</label><i className="fa fa-circle-question" style={{marginLeft:"5px", color:"#0d6efd"}}/>
                                        <TextInput
                                            name="count"
                                            id="count"
                                            type="number"
                                            autoFocus={true}
                                            value={count}
                                            className="form-control "
                                            onChange={(e) => setCount(e.target.value)}
                                            autoComplete="off"/>
                                    </div>
                                </div>
                            )}
                        </div>
                        {(activeForMessage === 0) && (
                            <Alert variant={"primary"} className="my-2">
                                As mensagens serão enviadas automaticamente a medida que você inicia o envio da campanha na tela de campanha ou ativa por webhook.
                            </Alert>
                        )}
                        {(activeForMessage === 1) && (
                            <Alert variant={"primary"} className="my-2">
                                Assim que o usuário entrar em contato esse fluxo irá iniciar.
                            </Alert>
                        )}
                        {(activeForMessage === 2) && (
                            <Alert variant={"primary"} className="my-2">
                                As mensagens serão enviadas para as TAGs selecionadas, de acordo com o agendamento configurado.
                            </Alert>
                        )}
                        {(activeForMessage === 3) && (
                            <Alert variant={"primary"} className="my-2">
                                O fluxo irá iniciar sempre que ocorrer algum evento no grupo.
                            </Alert>
                        )}
                    </div>

                    {(activeForMessage === 0) && (
                        <div style={{marginTop:"15px"}}>
                            <div style={{display:"flex", padding:"5px", borderRadius:"5px", border:"1px dashed #cacaca", flexDirection:"column"}}>  
                                <div style={{marginTop:"-15px"}}>
                                    <span style={{fontWeight:"bold", backgroundColor:"#fff"}}>Escolha ou Crie uma TAG para o contato recebido por WEBHOOK</span>
                                </div>

                                <div style={{display:"flex"}}>
                                    <div style={{width:"250px"}}>
                                        <label>Tags</label>
                                        <div className="input-group">
                                            <TagsInput value={selectedTags} onChange={e => handleTagInput(e)} beforeAddValidate={e => addValidationTag(e)}/>
                                        </div>
                                        <small className="text-danger">Após digitar a <span style={{fontWeight:"bold"}}>Tag</span>, aperte <span style={{fontWeight:"bold"}}>Enter</span></small>
                                    </div>     
                        
                                    <div style={{marginLeft:"15px"}}>
                                        <button title="Click aqui para incluir uma tag já existente" type="button" className="btn btn-success" style={{marginTop:"15px"}} onClick={e => handleTagInput(addValidationTag(tag) ? [...selectedTags,tag] : selectedTags)}><i className="fa fa-arrow-left"></i></button>
                                    </div>       
                        
                                    <div style={{marginLeft:"15px"}}>
                                        <div style={{width:"250px"}}>
                                            <span>Tags já existentes</span>
                                                <select className="form-select" value={tag} onChange={e => setTag(e.target.value)} style={{minHeight:"37px"}}>
                                                    <option value="">Selecione</option>
                                                    {dataTags.map((item) => {
                                                        return(<option value={item}>{item}</option>)
                                                    })} 
                                                </select>
                                        </div>
                                        <small className="text-danger">Selecione uma <span style={{fontWeight:"bold"}}>Tag</span> e click no botão ao lado para inserir</small>
                                    </div>
                                </div>
                            </div>
            
   
                        </div>
                    )}

                    {(activeForMessage === 1) && (
                        <div style={{padding:"5px", border:"1px dashed #cacaca", marginTop:"5px", borderRadius:"5px"}}>
                            <div style={{marginLeft:"5px", width:"50%"}}>
                                <label>Palavras de Ativação</label>
                                <div className="input-group w-100">
                                    <select
                                        name="triggerType"
                                        id="triggerType"
                                        className="form-select w-100"
                                        placeholder="Selecione"
                                        onChange={(e) => setTriggerType(parseInt(e.target.value))}
                                        value={triggerType}
                                    >
                                        <option value="">Selecione</option>
                                        {triggerOptions.map((data, id) => {
                                            return (
                                                <option key={id} value={data.value}>
                                                    {data.label}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                            {(triggerType === 1) && (
                                <div style={{marginLeft:"5px"}}>
                                    <div className="my-1 d-flex flex-direction-row " style={{display:"flex", flexDirection:"column"}}>
                                        <div style={{display:"flex"}}>
                                            <div style={{width:"100%"}}>
                                                <span>Palavras de Ativação</span>
                                                <label className="text-required">*</label>
                                                <div className="input-group">
                                                    <TextInput
                                                        name="triggerWord"
                                                        id="triggerWord"
                                                        type="text"
                                                        autoFocus={true}
                                                        maxLength={100}
                                                        value={triggerWord}
                                                        className="form-control "
                                                        onChange={(e) => setTriggerWord(e.target.value)}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-end justify-content-center" style={{marginLeft:"5px"}}>
                                                <button
                                                    type="button"
                                                    title="Clique para inserir a palavra"
                                                    className="btn btn-success"
                                                    onClick={(e) => addMessageActive()}
                                                >
                                                    <i className="fa fa-plus" />
                                                </button>
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{display:"flex",background:"#fff", width:"fit-content"}}>
                                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                                    <input type="checkbox" checked={triggerContent === 1} value={triggerContent === 1} onClick={e => setTriggerContent(triggerContent === 1 ? 0 : 1)} />
                                                    <span style={{ marginTop: "5px" }}></span>
                                                </label>
                                                <span style={{marginLeft:"5px",marginTop:"5px"}}>O texto pode estar em qualquer parte da mensagem?</span>
                                            </div>  
                                            <div style={{display:"flex",background:"#fff", width:"fit-content"}}>
                                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                                    <input type="checkbox" checked={triggerContent === 0} value={triggerContent === 0} onClick={e => setTriggerContent(triggerContent === 1 ? 0 : 1)} />
                                                    <span style={{ marginTop: "5px" }}></span>
                                                </label>
                                                <span style={{marginLeft:"5px",marginTop:"5px"}}>A mensagem deve ser igual?</span>
                                            </div>  
                                        </div>
                                    </div>
                                    <div style={{marginTop:"15px"}}>
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className="own-acordion">
                                                    <span className="w-100">Palavras Gatilhos</span>
                                                </Accordion.Header>
                                            <Accordion.Body className="p-0" style={{height:"150px", overflow:"auto"}}>
                                                    <table className="table table-striped w-100" style={{maxHeight:"150px", overflow:"auto"}}>
                                                        <tbody>
                                                            {messagesActive.map((_actions, id) => {
                                                                return (
                                                                    <tr className="gradeX" key={id}>
                                                                        <td style={{width:"100%"}}>
                                                                            {_actions.triggerWord}
                                                                        </td>
                                                                        <td style={{width:"100%",textAlign:"center"}}>
                                                                            <div className={`badge ${_actions.triggerContent === 0 ? 'badge-success' : 'badge-warning'}`}>{_actions.triggerContent === 0 ? "Mensagem Igual" : "Contenha a Palavra"}</div>
                                                                        </td>
                                                                        <td>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-danger btn-sm"
                                                                                onClick={(e) =>
                                                                                    removeMessageActive(_actions.triggerWord)
                                                                                }
                                                                            >
                                                                                <i className="fa fa-trash" />
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                      
                            )}
                        </div>
                    )}
               
                    {(activeForMessage === 2) && (
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <div style={{display:"flex", gap:"5px"}}>
                                    <div style={{marginLeft:"5px"}}>
                                        <label>Executar</label>
                                        <div className="input-group w-100">
                                            <select
                                                name="activateOption"
                                                id="activateOption"
                                                className="form-select w-100"
                                                placeholder="Selecione"
                                                onChange={(e) => setActivateOption(parseInt(e.target.value))}
                                                value={activateOption}
                                            >
                                                <option value="">Selecione</option>
                                                {activationOptions.map((data, id) => {
                                                    return (
                                                        <option key={id} value={data.value}>
                                                            {data.label}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>   
                                    <div style={{display:"flex", gap:"5px"}}>
                                        {(activateOption === 0) && (
                                            <div>
                                                <label>Dia da semana</label>
                                                <div className="input-group1">
                                                    <select
                                                        name="dayweek"
                                                        id="dayweek"
                                                        className="form-select w-100"
                                                        placeholder="Selecione"
                                                        onChange={(e) => setDayWeek(parseInt(e.target.value))}
                                                        value={dayWeek}
                                                    >
                                                        <option value="">Selecione</option>
                                                        <option value="0">Domingo</option>
                                                        <option value="1">Segunda-Feira</option>
                                                        <option value="2">Terça-Feira</option>
                                                        <option value="3">Quarta-Feira</option>
                                                        <option value="4">Quinta-Feira</option>
                                                        <option value="5">Sexta-Feira</option>
                                                        <option value="6">Sábado</option>
                                                        <option value="7">Segunda à Sexta</option>
                                                        <option value="8">Segunda à Sábado</option>
                                                    </select>
                                                </div>
                                            </div>
                                         )}

                                        {(activateOption === 1) && (
                                            <div>
                                                <label>Dia</label>
                                                <div className="input-group1">
                                                    <TextInput
                                                        name="days"
                                                        id="days"
                                                        type="number"
                                                        autoFocus={true}
                                                        maxLength={100}
                                                        value={day}
                                                        className="form-control "
                                                        onChange={(e) => setDay(parseInt(e.target.value))}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                         )}

                                        <div style={{display:"flex",gap:"5px"}} title={activateOption === 2 ? 'A automação irá iniciar quando algum dos chatbots ativos, estiverem sem resposta por parte do atendente, por um período maior que o informado abaixo' : ''}>
                                            <div>
                                                <label>{activateOption === 2 ? 'por' : 'as '}</label>{activateOption === 2 ? <i className="fa fa-circle-question" style={{ marginLeft: "5px", color: "#0d6efd" }} /> : ''}
                                                <div className="input-group1">
                                                    <TextInput
                                                        name="hours"
                                                        id="hours"
                                                        type="time"
                                                        maxLength={100}
                                                        value={hours}
                                                        className="form-control "
                                                        onChange={(e) => setHours(e.target.value)}
                                                        autoComplete="off"
                                                            />
                                                </div>
                                            </div>
                                            {activateOption === 2 ? (
                                                <div>
                                                    <div style={{display:'flex', gap:"5px"}}>
                                                        <div >
                                                            <label>Chatbots</label>
                                                            <select className="form-select" style={{width:"205px"}} value={idautomationchat} onChange={e => setIdAutomationChat(parseInt(e.target.value))}>
                                                                <option value="">Selecione</option>
                                                                {chatData.map((item) => {
                                                                    return (<option value={item.idautomationchat}>{item.title}</option>)
                                                                })}
                                                            </select>
                                                        </div>
                                                        <div style={{display:"flex", alignItems:"end"}}>
                                                            <button type="button" className="btn btn-success" onClick={e => addChatbot()}><i className="fa fa-plus"/></button>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <table className='table table-striped w-100' style={{height:'10px', marginTop:"5px"}}>
                                                            <thead className="thead-light">
                                                                <tr className='head-th'>
                                                                    <th style={{width:"100%"}}>Chatbot</th>
                                                                    <th >...</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {chatbots?.map(_item => {
                                                                    return(
                                                                        <tr className="gradeX">
                                                                            <td>{_item.description}</td>
                                                                            <td>
                                                                                <button type="button" className="btn btn-danger btn-sm" onClick={(e) => removeChatBot(_item.idautomationchat)}>
                                                                                    <i className="fa fa-trash" />
                                                                                </button>
                                                                            </td>
                                                                        </tr>)
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            ) : ''}
                                            </div>
                                    </div>
                                </div>
                                {(activateOption !== 2) && (
                                    <div style={{marginTop:"15px"}}>
                                        <div style={{display:"flex", padding:"5px", borderRadius:"5px", border:"1px dashed #cacaca", flexDirection:"column"}}>  
                                            <div style={{marginTop:"-15px"}}>
                                                <span style={{fontWeight:"bold", backgroundColor:"#fff"}}>Para qual TAG deseja enviar o fluxo?</span>
                                            </div>

                                            <div style={{display:"flex"}}>
                                                <div style={{width:"250px"}}>
                                                    <label>Tags</label>
                                                    <div className="input-group">
                                                        <TagsInput value={selectedTags} onChange={e => handleTagInput(e)} beforeAddValidate={e => addValidationTag(e)}/>
                                                    </div>
                                                    <small className="text-danger">Após digitar a <span style={{fontWeight:"bold"}}>Tag</span>, aperte <span style={{fontWeight:"bold"}}>Enter</span></small>
                                                </div>     
    
                                                <div style={{marginLeft:"15px"}}>
                                                    <button title="Click aqui para incluir uma tag já existente" type="button" className="btn btn-success" style={{marginTop:"15px"}} onClick={e => handleTagInput(addValidationTag(tag) ? [...selectedTags,tag] : selectedTags)}><i className="fa fa-arrow-left"></i></button>
                                                </div>       
    
                                                <div style={{marginLeft:"15px"}}>
                                                    <div style={{width:"250px"}}>
                                                        <span>Tags já existentes</span>
                                                        <select className="form-select" value={tag} onChange={e => setTag(e.target.value)} style={{minHeight:"37px"}}>
                                                            <option value="">Selecione</option>
                                                            {dataTags.map((item) => {
                                                                return(<option value={item}>{item}</option>)
                                                            })} 
                                                        </select>
                                                    </div>
                                                    <small className="text-danger">Selecione uma <span style={{fontWeight:"bold"}}>Tag</span> e click no botão ao lado para inserir</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                    )}
                    
                    {(activeForMessage === 3) && (
                        <div>
                            <div style={{display:"flex", gap:"5px"}}>
                                <div style={{marginTop:'5px', width:"100%"}}>
                                    <span>Selecione o grupo desejado</span>
                                    <select className="form-select" value={group} onChange={e => handleGroup(e.target.value,e.target.options[e.target.selectedIndex].text)}>
                                        <option value="">Selecione</option>
                                        {dataGroups.map((item) => {
                                            return(<option value={item.id.user}>{item.name}</option>)
                                        })}
                                    </select>
                                </div>
                                <div style={{display:"flex",alignItems:"end"}}>
                                    <button className="btn btn-success" type="button" onClick={e => addGroup(e)}><i className="fa fa-plus"/></button>
                                </div>
                            </div>
                            <div style={{marginTop:"15px"}}>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header className="own-acordion">
                                            <span className="w-100">Grupos</span>
                                        </Accordion.Header>
                                        <Accordion.Body className="p-0" style={{height:"150px", overflow:"auto"}}>
                                            <table className="table table-striped w-100" style={{maxHeight:"150px", overflow:"auto"}}>
                                                <tbody>
                                                    {groups.map((_actions, id) => {
                                                        return (
                                                            <tr className="gradeX" key={id}>
                                                                <td style={{width:"100%"}}>
                                                                    {_actions.chat}
                                                                </td>
                                                                <td>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-danger btn-sm"
                                                                        onClick={(e) =>
                                                                            removeGroup(_actions.chatId)
                                                                        }>
                                                                        <i className="fa fa-trash" />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit" id="submit-start-node-modal">
                    <em className="fa fa-save"></em> Aplicar
                </Button>
                <Button
                    type="button"
                    className="btn-ligth-gray"
                    onClick={handleCloseModal}
                >
                    <em className="fa fa-door-closed"></em> Fechar
                </Button>
            </Modal.Footer>
        </ValidationForm>
    </Modal>
  );
}
