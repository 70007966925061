import React, { useEffect, useState } from 'react';

import { Modal, ModalDialog } from 'react-bootstrap';
import Draggable from 'react-draggable';
import { Button } from 'reactstrap';
import ShowMessages from '../../components/Exceptions';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic';
import loadingButton from '../layouts/Buttons';


import { TagsInput } from "react-tag-input-component";

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalLeadTransferTag({showModal, handleCloseModal, handleReload}) {
    const [tags, setTags]                           = useState([]);
    const [tagsTarget, setTagsTarget]               = useState([]);

    const [dataTags, setDataTags]                   = useState([])
    const [tag, setTag]                             = useState('')
    const [tagTarget, setTagTarget]                 = useState('')


    const addValidationTag = (_tag) => {
        if (_tag.trim() === '')
            return false
        _tag = _tag.toString().toUpperCase()
        return !tags.includes(_tag)
    }

    const addValidationTagTarget = (_tag) => {
        if (_tag.trim() === '')
            return false
        _tag = _tag.toString().toUpperCase()
        return !tagsTarget.includes(_tag)
    }

    const handleTagInput = (value) => { 
        value = value.map(function(x){ return x.toString().toUpperCase().replace(/[^a-zA-Z0-9_]/g,''); })     
        
        if (value === '')
            return false
        
        if (tags.length !== value.length)
            setTags(value)
    }

    const handleTagInputTarget = (value) => { 
        value = value.map(function(x){ return x.toString().toUpperCase().replace(/[^a-zA-Z0-9_]/g,''); })     
        
        if (value === '')
            return false
        
        if (tagsTarget.length !== value.length)
            setTagsTarget(value)
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        

        if (tags.length <= 0) {
            ShowMessages({status : 500, message : "Nenhuma tag foi informada!"})
            return
        }

        try {
            let _dataToSend = {
                tags,
                tagsTarget
            }

            if (tags.length <= 0) {
                ShowMessages({status : 500, message : "Nenhuma tag de origem foi informada!"})
                return
            }

            if (tagsTarget.length <= 0) {
                ShowMessages({status : 500, message : "Nenhuma tag de destino foi informada!"})
                return
            }

            if (tagsTarget.length > 1) {
                ShowMessages({status : 500, message : "Você só pode incluir uma TAG para o destino!"})
                return
            }

            
            loadingButton(true,document.querySelector('#submit-lead'),document.querySelector('#submit-lead').children[0].classList,document.querySelector('#submit-lead').lastChild.nodeValue)

            await api.post('/leads/transfer', _dataToSend)
                      .then(response => {
                         ShowMessages(response)
                         if (response.ok) {
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else
                                 loadingButton(false,document.querySelector('#submit-lead'),'fa fa-save','Tranferir')
                         } else
                             loadingButton(false,document.querySelector('#submit-lead'),'fa fa-save','Tranferir')
                     })
                     .catch( error => {
                         loadingButton(false,document.querySelector('#submit-lead'),'fa fa-save','Tranferir')
                         ShowMessages(error) 
                     })

        } catch (response) {
            loadingButton(false,document.querySelector('#submit-lead'),'fa fa-save','Tranferir')
            ShowMessages(response)
        }
    }


    useEffect(() => {
        getData()
        setStatistic('modals\\modal-lead-transfer-tags')
    } ,[])

    const getData = async() => {
        try {
            await api.get('/getDataCampaing')
                    .then(response => {                    
                        setDataTags(response.data.tags)
                    })
                    .catch( error => {      
                        ShowMessages(error) 
                    })
        } catch (response) { 
            ShowMessages(response)
        }
    }

    return (
        <Modal size="lg" show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogClassName="width550" dialogAs={DraggableModalDialog}>
            <Modal.Header closeButton>
                <Modal.Title style={{marginBottom:"0px"}}>
                    <i className="fa fa-right-left"></i> Transferência de Tags
                </Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
                <div style={{display:"flex", flexDirection:"column"}}> 
                    <div style={{border:"1px dashed #cacaca", padding:"5px", borderRadius:"5px"}}>
                        <div style={{marginTop:"-15px"}}>
                            <label style={{background:"#fff", fontWeight:"bold"}}>Transferir Contatos da TAG...</label>
                        </div>
                        
                        <div style={{display:"flex"}}>
                            <div style={{width:"400px"}}>
                                <label>Tags</label>
                                <div className="input-group">
                                    <TagsInput value={tags} onChange={e => handleTagInput(e)} beforeAddValidate={e => addValidationTag(e)}/>
                                </div>
                                <small className="text-danger">Após digitar a <span style={{fontWeight:"bold"}}>Tag</span>, aperte <span style={{fontWeight:"bold"}}>Enter</span></small>
                            </div>     
                        
                            <div style={{marginLeft:"15px"}}>
                                <button title="Click aqui para incluir uma tag já existente" type="button" className="btn btn-success" style={{marginTop:"15px"}} onClick={e => handleTagInput(addValidationTag(tag) ? [...tags,tag] : tags)}><i className="fa fa-arrow-left"></i></button>
                            </div>       
                        
                            <div style={{marginLeft:"15px"}}>
                                <div style={{width:"300px"}}>
                                    <span>Tags já existentes</span>
                                    <select className="form-select" value={tag} onChange={e => setTag(e.target.value)} style={{minHeight:"37px"}}>
                                        <option value="">Selecione</option>
                                        {dataTags.map((item) => {
                                            return(<option value={item}>{item}</option>)
                                    })} 
                                    </select>
                                </div>
                                <small className="text-danger">Selecione uma <span style={{fontWeight:"bold"}}>Tag</span> e click no botão ao lado para inserir</small>
                            </div>
                        </div>
                    </div> 

                    <div style={{border:"1px dashed #cacaca", padding:"5px", borderRadius:"5px", marginTop:"30px"}}>
                        <div style={{marginTop:"-15px"}}>
                            <label style={{background:"#fff", fontWeight:"bold"}}>Para a TAG...</label>
                        </div>
                        
                        <div style={{display:"flex"}}>
                            <div style={{width:"400px"}}>
                                <label>Tags</label>
                                <div className="input-group">
                                    <TagsInput value={tagsTarget} onChange={e => handleTagInputTarget(e)} beforeAddValidate={e => addValidationTagTarget(e)}/>
                                </div>
                                <small className="text-danger">Após digitar a <span style={{fontWeight:"bold"}}>Tag</span>, aperte <span style={{fontWeight:"bold"}}>Enter</span></small>
                            </div>     
                        
                            <div style={{marginLeft:"15px"}}>
                                <button title="Click aqui para incluir uma tag já existente" type="button" className="btn btn-success" style={{marginTop:"15px"}} onClick={e => handleTagInputTarget(addValidationTagTarget(tag) ? [...tagsTarget,tagTarget] : tagsTarget)}><i className="fa fa-arrow-left"></i></button>
                            </div>       
                        
                            <div style={{marginLeft:"15px"}}>
                                <div style={{width:"300px"}}>
                                    <span>Tags já existentes</span>
                                    <select className="form-select" value={tagTarget} onChange={e => setTagTarget(e.target.value)} style={{minHeight:"37px"}}>
                                        <option value="">Selecione</option>
                                        {dataTags.map((item) => {
                                            return(<option value={item}>{item}</option>)
                                    })} 
                                    </select>
                                </div>
                                <small className="text-danger">Selecione uma <span style={{fontWeight:"bold"}}>Tag</span> e click no botão ao lado para inserir</small>
                            </div>
                        </div>
                    </div> 
   
                </div>
            </Modal.Body>
            <Modal.Footer>  
                <Button type="submit" id="submit-lead" className={`btn-theme`} onClick={e => handleSubmit(e)}>
                    <em className="fa fa-save"></em> Transferir
                </Button>          
                <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                    <em className="fa fa-door-closed"></em> Fechar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}