import React, { useEffect, useState } from "react";

import { Button, Modal, ModalDialog } from "react-bootstrap";
import { TextInput, ValidationForm } from "react-bootstrap4-form-validation";
import Draggable from "react-draggable";
import { useReactFlow } from "reactflow";
import api from '../../../services/api';
import { TagsInput } from "react-tag-input-component";
import ShowMessages from "../../../components/Exceptions";

class DraggableModalDialog extends React.Component {
    render() {
        return (
            <Draggable handle=".modal-header">
                <ModalDialog {...this.props} />
            </Draggable>
        );
    }
}

const actionOptions = [
    {
        label: "Executar a próxima ação em...",
        value: 0,
    },
    {
        label: "Aguardar o usuário responder",
        value: 1,
    },
    {
        label: "Alteração ou Substituição de TAG",
        value: 2,
    },
    {
        label: "Enviar Status de Gravando...",
        value: 3,
    },
    {
        label: "Enviar Status de Digitando...",
        value: 4,
    }
];

export default function ModalActionNode({ nodeId, showModal, handleCloseModal, nodeData }) {
    const [actionType, setActionType] = useState(0);
    const [timeToAction, setTimeToAction] = useState(30);

    const [dataTags, setDataTags] = useState([])
    const [selectedTags, setSelectTags] = useState([])
    const [selectedReplaceTags, setSelectedReplaceTags] = useState([])
    const [tag, setTag] = useState('')
    const [tagReplace, setTagReplace] = useState('')
    const [optionTag, setOptionTag] = useState(0)

    const reactFlowInstance = useReactFlow();

    useEffect(() => {
        setActionType(nodeData.actionType);
        setTimeToAction(nodeData.timeToAction);
        setSelectTags(nodeData.selectedTags);
        setSelectedReplaceTags(nodeData?.selectedReplaceTags || [])
        setOptionTag(nodeData.optionTag || 0)
    }, [nodeData]);

    useEffect(() => {
        getDataTags()
    }, []);

    const handleTagInput = (value) => {
        value = value.map(function (x) { return x.toString().toUpperCase().replace(/[^a-zA-Z0-9_]/g, ''); })

        if (value === '')
            return false

        if (selectedTags.length !== value.length)
            setSelectTags(value)
    }

    const addValidationTag = (_tag) => {
        if (_tag.trim() === '')
            return false
        _tag = _tag.toString().toUpperCase()
        return !selectedTags.includes(_tag)
    }

    const handleTagInputReplace = (value) => {
        value = value.map(function (x) { return x.toString().toUpperCase().replace(/[^a-zA-Z0-9_]/g, ''); })

        if (value === '')
            return false

        if (selectedReplaceTags.length !== value.length)
            setSelectedReplaceTags(value)
    }

    const addValidationTagReplace = (_tag) => {
        if (_tag.trim() === '')
            return false
        _tag = _tag.toString().toUpperCase()
        return !selectedReplaceTags.includes(_tag)
    }

    const getDataTags = async () => {
        try {
            await api.get('/getDataCampaing')
                .then(response => {
                    setDataTags(response.data.tags)
                })
                .catch(error => {
                    ShowMessages(error)
                })
        } catch (response) {
            ShowMessages(response)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let option
        if (actionType === 0) {
            if (timeToAction > 600) {
                ShowMessages({ status: 500, message: "Esse tipo de ação não pode ter mais que 600 segundos" })
                return false
            }

            if (timeToAction < 1) {
                ShowMessages({ status: 500, message: "Esse tipo de ação não pode ter menos que 1 segundo" })
                return false
            }
        }
        
        if (actionType === 1) {
            if (timeToAction > 172800) {
                ShowMessages({ status: 500, message: "Esse tipo de ação não pode ter mais que 86400 segundos(48 horas)" })
                return false
            }

            if (timeToAction < 0) {
                ShowMessages({ status: 500, message: "Esse tipo de ação não pode ter menos que 0" })
                return false
            }
            
            if (timeToAction > 0) 
                option = {option : [{id : 6, targetId : -1}]}
        }

        if ([3, 4].includes(actionType)) {
            if (timeToAction > 150) {
                ShowMessages({ status: 500, message: "Esse tipo de ação não pode ter mais que 150 segundos" })
                return false
            }

            if (timeToAction < 1) {
                ShowMessages({ status: 500, message: "Esse tipo de ação não pode ter menos que 1 segundo" })
                return false
            }
        }
        
        reactFlowInstance.setNodes((nds) =>
            nds.map((node) => {
                if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        nodeInfo: {
                            node: parseInt(nodeData.node),
                            parentId: parseInt(nodeData.parentId),
                            targetId: parseInt(nodeData.targetId),
                            objectType: 8,
                            selectedTags,
                            selectedReplaceTags,
                            timeToAction,
                            optionTag,
                            actionType,
                            ...option
                        }
                    }
                }

                return node;
            })
        )

        handleCloseModal();
    };

    return (
        <Modal size="md" onSubmit={handleSubmit} show={showModal} onHide={handleCloseModal} dialogClassName=" width500" animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
            <ValidationForm className="modal-content" setFocusOnError={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="fa fa-person-running"></i> Ação
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="box-shadow" style={{ padding: "10px" }}>
                            <div className="mb-3">
                                <label>Tipo de Ação</label>
                                <div className="input-group w-100">
                                    <select
                                        name="actionType"
                                        id="actionType"
                                        className="form-select w-100"
                                        placeholder="Selecione"
                                        onChange={(e) => setActionType(parseInt(e.target.value))}
                                        value={actionType}>
                                        <option value="">Selecione</option>
                                        {actionOptions.map((data, id) => {
                                            return (<option key={id} value={data.value}>{data.label}</option>);
                                        })}
                                    </select>
                                </div>
                            </div>
                            {[0, 3, 4].includes(actionType) && (
                                <div className="mb-2">
                                    <div className="input-group">
                                        <div>
                                            <div style={{ display: "flex", width: "150px" }}>
                                                <TextInput
                                                    name="timeToAction"
                                                    id="timeToAction"
                                                    type="number"
                                                    required
                                                    autoFocus={true}
                                                    maxLength={15}
                                                    errorMessage={{
                                                        required: "Por favor, informe uma tempo!",
                                                    }}
                                                    value={timeToAction}
                                                    className="form-control "
                                                    onChange={(e) => setTimeToAction(Number(e.target.value))}
                                                    autoComplete="off"
                                                />
                                                <div style={{ marginLeft: "5px", marginTop: "5px" }}>
                                                    <span>Segundos</span>
                                                </div>
                                            </div>
                                            <div>
                                                <small className="text-danger">Max. de  {[3, 4].includes(actionType) ? "150" : "600"} seg.</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {actionType === 1 && (
                                <div style={{ display: "flex", width: "190px", flexDirection:"column" }}>
                                    <label>Continuar a automação após</label>
                                    <TextInput
                                        name="timeToAction"
                                        id="timeToAction"
                                        type="number"
                                        required
                                        autoFocus={true}
                                        maxLength={15}
                                        errorMessage={{
                                            required: "Por favor, informe uma tempo!",
                                        }}
                                        value={timeToAction}
                                        className="form-control "
                                        onChange={(e) => setTimeToAction(Number(e.target.value))}
                                        autoComplete="off"
                                    />
                                    <small style={{color:"#dc3545"}}>Em caso de 0, a opção e desativada</small>
                                    <div style={{ marginTop: "5px" }}>
                                        <span>segundos sem resposta</span>
                                    </div>
                                </div>
                            )}
                            {actionType === 2 && (
                                <div style={{ display: "flex", padding: "5px", borderRadius: "5px", border: "1px dashed #cacaca", flexDirection: "column" }}>
                                    <div style={{ marginTop: "-15px" }}>
                                        <span style={{ fontWeight: "bold", backgroundColor: "#fff" }}>Opções de TAGs</span>
                                    </div>
                                    
                                    <div style={{display:"flex", padding:"10px 50px", width:"100%", justifyContent:"space-between", background:"#f7f8f9"}}>
                                        <div style={{display:"flex", alignItems:"center", gap:"5px", cursor:"pointer"}} title="Substitui todas as TAGs do contato pela TAG Informada">
                                            <input type="radio" value={optionTag} checked={optionTag === 0} onChange={e => setOptionTag(0)} style={{cursor:"pointer"}}/><span> Alterar TAG</span>
                                        </div>
                                        <div style={{display:"flex", alignItems:"center", gap:"5px", cursor:"pointer"}} title="Altera a TAG informada por outra">
                                            <input type="radio" value={optionTag} checked={optionTag === 1} onChange={e => setOptionTag(1)} style={{cursor:"pointer"}}/><span> Substituir TAG</span>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", marginTop:"15px", flexDirection:"column" }}>
                                        <div>
                                            <span style={{fontWeight:"bold"}}>{optionTag === 0 ? 'Alterar pela TAG abaixo' : 'Substituir as TAGs abaixo'}</span>
                                        </div>
                                        <div style={{display:"flex"}}>
                                            <div style={{ width: "250px" }}>
                                            <label>Tags</label>
                                            <div className="input-group">
                                                <TagsInput value={selectedTags} onChange={e => handleTagInput(e)} beforeAddValidate={e => addValidationTag(e)} />
                                            </div>
                                            <small className="text-danger">Após digitar a <span style={{ fontWeight: "bold" }}>Tag</span>, aperte <span style={{ fontWeight: "bold" }}>Enter</span></small>
                                            </div>

                                            <div style={{ marginLeft: "15px" }}>
                                            <button title="Click aqui para incluir uma tag já existente" type="button" className="btn btn-success" style={{ marginTop: "15px" }} onClick={e => handleTagInput(addValidationTag(tag) ? [...selectedTags, tag] : selectedTags)}><i className="fa fa-arrow-left"></i></button>
                                            </div>

                                            <div style={{ marginLeft: "15px" }}>
                                            <div style={{ width: "200px" }}>
                                                <span>Tags já existentes</span>
                                                <select className="form-select" value={tag} onChange={e => setTag(e.target.value)} style={{ minHeight: "37px" }}>
                                                    <option value="">Selecione</option>
                                                    {dataTags.map((item) => {
                                                        return (<option value={item}>{item}</option>)
                                                    })}
                                                </select>
                                            </div>
                                            <small className="text-danger">Selecione uma <span style={{ fontWeight: "bold" }}>Tag</span> e click no botão ao lado para inserir</small>
                                            </div>
                                        </div>
                                    </div>

                                    {optionTag === 1 && (
                                        <div style={{ display: "flex", marginTop:"15px", flexDirection:"column" }}>
                                            <div>
                                                <span style={{fontWeight:"bold"}}>Novas TAGs do contato</span>
                                            </div>
                                            <div style={{ display: "flex" }}>
                                            <div style={{ width: "250px" }}>
                                                <label>Tags</label>
                                                <div className="input-group">
                                                    <TagsInput value={selectedReplaceTags} onChange={e => handleTagInputReplace(e)} beforeAddValidate={e => addValidationTagReplace(e)} />
                                                </div>
                                                <small className="text-danger">Após digitar a <span style={{ fontWeight: "bold" }}>Tag</span>, aperte <span style={{ fontWeight: "bold" }}>Enter</span></small>
                                            </div>

                                            <div style={{ marginLeft: "15px" }}>
                                                <button title="Click aqui para incluir uma tag já existente" type="button" className="btn btn-success" style={{ marginTop: "15px" }} onClick={e => handleTagInputReplace(addValidationTagReplace(tagReplace) ? [...selectedReplaceTags, tagReplace] : selectedReplaceTags)}><i className="fa fa-arrow-left"></i></button>
                                            </div>

                                            <div style={{ marginLeft: "15px" }}>
                                                <div style={{ width: "200px" }}>
                                                    <span>Tags já existentes</span>
                                                    <select className="form-select" value={tagReplace} onChange={e => setTagReplace(e.target.value)} style={{ minHeight: "37px" }}>
                                                        <option value="">Selecione</option>
                                                        {dataTags.map((item) => {
                                                            return (<option value={item}>{item}</option>)
                                                        })}
                                                    </select>
                                                </div>
                                                <small className="text-danger">Selecione uma <span style={{ fontWeight: "bold" }}>Tag</span> e click no botão ao lado para inserir</small>
                                            </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" id="submit-action-info">
                        <em className="fa fa-save"></em> Aplicar
                    </Button>
                    <Button type="button" className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
    );
}
