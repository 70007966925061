import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput, SelectGroup } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import  loadingButton  from '../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalWhatsAppGroup({showModal, handleCloseModal, data, handleReload}) {
    const [loading, setLoading]           = useState(false)
    const [type, setType]                 = useState(0)
    const [link, setLink]                 = useState('')
    const [groupName, setGroupName]       = useState('')
    const [number, setNumber]             = useState('')

    const [tag, setTag]                   = useState('')
    const [selectTags, setSelectTags]     = useState([])

    const [dataTags, setDataTags]         = useState([])
    const [dataNumber, setDataNumber]     = useState([])
    const [totalPeoples, setTotalPeoples] = useState(0)

    const setData = () => {
        setType(0)
        setLink('')
        setGroupName('')
        setNumber('')
        setTag('')
        setSelectTags([])
        setTotalPeoples(0)
    }

    const getStatistics = async(tag) => {
        try {
            await api.post('/getCampaignStatistics',
                     {tag})
                    .then(response => {
                        setTotalPeoples(response.data.reach)  
                        setLoading(false)
                    })
                    .catch( error => {      
                        ShowMessages(error) 
                        setLoading(false)
                    })
        } catch (response) {  
            ShowMessages(response)
            setLoading(false)
        }

    }

    const addTag = () => {
        const tagAlreadyExistInArrayTags = selectTags.some((item) => {
            if(item.tag === tag) {
                return true
            }
            return false;
        })

        if(tag.length === 0){
            ShowMessages({status : 500, message : "Tag não informada"})
            return
        }
        if(selectTags.length >= 5){
            ShowMessages({status : 500, message : "Permitido apenas 5 tags"})
            return
        }
        
        if(tagAlreadyExistInArrayTags){
            ShowMessages({status : 500, message : "Tag já existe"})
            return
        }
        
        getStatistics([...selectTags, {tag: tag}])
        setSelectTags([...selectTags, {tag: tag}]);

    };

    const removeTag = (_tag) => {
        let arrayOfTags = selectTags.filter((item) => {
            return item.tag !== _tag;
        })

        getStatistics(arrayOfTags)
        setSelectTags(arrayOfTags);
    }

    const getData = async() => {
        try {
            await api.get('/getDataCampaing')
                    .then(response => {
                        setDataNumber(response.data.numbers)   
                        
                        setDataTags(response.data.tags)
                    })
                    .catch( error => {      
                        ShowMessages(error) 
                    })
        } catch (response) { 
            ShowMessages(response)
        }
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-group'),document.querySelector('#submit-group').children[0].classList,document.querySelector('#submit-group').lastChild.nodeValue)

        try {
            let _data = { type,
                          link,
                          groupName,
                          number,
                          selectTags }
            
            await api.post(type === 0 ? '/entryGroup' : '/createGroup', _data)
                      .then(response => {
                          ShowMessages(response)
                          if (response.ok) {   
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else 
                                 loadingButton(false,document.querySelector('#submit-group'))        
                          } else
                             loadingButton(false,document.querySelector('#submit-group'))
                       })
                       .catch( error => {
                         loadingButton(false,document.querySelector('#submit-group'))
                         ShowMessages(error) 
                       })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-group'))
            ShowMessages(response)
        }
    }

    useEffect(() => {
        setData()
        getData()
    },[data,showModal])
   
    useEffect(() => {
        setStatistic('modals\\modal-whatsapp-group')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-users"></i> Propriedades de Grupos
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading}/>
                    <div className={`${loading ? "hidden" : ""}`}>  
                        <div style={{padding:"15px", borderRadius:"5px", border:"1px solid #cacaca", justifyContent:"center",display:"flex"}}>
                            <input type="radio" name="type[]" checked={type === 0} value={type} onClick={e => setType(0)}/><span style={{marginLeft:"5px"}}>Entrar no Grupo</span>
                            <input type="radio" name="type[]" checked={type === 1} value={type} onClick={e => setType(1)} style={{marginLeft:"15px"}}/><span style={{marginLeft:"5px"}}>Criar o Grupo</span>
                        </div>    
                        <div style={{width:"400px"}}>
                            <span>Número</span><label className="text-required">*</label>
                            <select className="form-select" value={number} required onChange={e => setNumber(e.target.value)} >
                                <option value="">Selecione</option>
                                    {dataNumber.map((item) => {
                                        return(<option value={item.number}>{item.phone_format} - {item.description}</option>)
                                    })}
                            </select>
                        </div>
                                                     
                        {type === 0 ?
                            <div>
                                <label>Link do Grupo</label>
                                <input className="form-control" type="text" value={link} onChange={e => setLink(e.target.value)}/>
                            </div>
                        : 
                            <div style={{width:"400px"}}>
                                <div>
                                    <label>Nome do Grupo</label>
                                    <input className="form-control" required type="text" value={groupName} onChange={e => setGroupName(e.target.value)}/>
                                </div>  
                                <div style={{display:"flex"}}>
                                    <div style={{width:"400px"}}>
                                        <span>Tags dos Contatos a Inserir no Grupo</span>
                                        <select className="form-select" value={tag} onChange={e => setTag(e.target.value)} >
                                            <option value="">Selecione</option>
                                            {dataTags.map((item) => {
                                                return(<option value={item}>{item}</option>)
                                            })}
                                        </select>        
                                    </div>
                                    <div style={{display:"flex",alignItems:"center", marginLeft:"10px", width:"40px"}}>
                                        <button type="button" title="Clique para inserir a tag" className="btn btn-success" style={{marginTop:"18px"}} onClick={e => addTag()} ><i className="fa fa-plus"/></button>
                                    </div>
                                </div>
                                <div>
                                    <small style={{fontSize:"10px"}}className="text-danger">Após escolher a <span style={{fontWeight:"bold"}}>Tag</span>, click no botão ao lado.</small>
                                </div>
                                <div style={{marginTop:"5px", width:"400px"}}>
                                    <table className={`table table-striped w-100 ${selectTags.length < 0 ? "hidden" : ""}`}>
                                        <thead className="thead-theme">
                                            <tr>
                                                <td>Tags</td>
                                                <td style={{textAlign:"center"}}>...</td>
                                            </tr>
                                        </thead>  
                                        <tbody>                        
                                            {selectTags.map((_actions,id) => {
                                                return(<tr className="gradeX w-100" key={id}>
                                                            <td>{_actions.tag}</td>
                                                            <td style={{textAlign:"center",verticalAlign: "middle"}}>
                                                                <button type='button' className="btn btn-danger btn-sm" onClick={e => removeTag(_actions.tag)}>
                                                                    <i className="fa fa-trash"/>
                                                                </button>
                                                            </td>
                                                        </tr>)
                                            }) }
                                        </tbody> 
                                    </table> 
                                    <div className={`box-sem-dados alert alert alert-warning ${selectTags.length > 0 ? "hidden" : ""}`}><span style={{color:"#000"}}>Ainda não foi incluido nenhuma tag.</span></div>   
                                </div>
                                <div className={`box-sem-dados alert alert alert-warning ${totalPeoples <= 249 ? "hidden" : ""}`}>
                                    <div style={{display:"flex",alignItems:"center"}}>
                                        <i className='fa fa-triangle-exclamation' style={{color:"#000", fontSize:"48px"}}/>
                                    </div>
                                    <div style={{marginLeft:"15px"}}>
                                        <span style={{color:"#000"}}>Os grupos que você criou possui mais de 250 contatos, ao criar o grupo será inserido apenas os primeiros 250 contatos encontrados!</span>
                                    </div>  
                                </div>
                            </div>
                        }                                        
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-group" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-circle-plus"></em> {type === 0 ? ' Entrar' : 'Criar'}
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}