import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Draggable from 'react-draggable';

import { TagsInput } from "react-tag-input-component";

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import loadingButton  from '../layouts/Buttons'


class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalImportGoogle({showModal, handleCloseModal}) {
    const [showResult, setShowResult]         = useState(false)

    const [importUnnamed, setImportUnnamed]   = useState(true)
    const [onlyScheduling, setOnlyScheduling] = useState(true)
    const [groupmanagement,setGroupmanagement]= useState(true)
    const [tagBlock, setTagBlock]             = useState(false)

    const [tags, setTags]                     = useState([])
    const [number, setNumber]                 = useState('') 

    const [dataResult, setDataResult]         = useState([])
    const [dataNumber, setDataNumbers]        = useState([])

    const [dataTags, setDataTags]            = useState([])
    const [tag, setTag]                      = useState('')

    const addValidationTag = (_tag) => {
        if (_tag.trim() === '')
            return false
        _tag = _tag.toString().toUpperCase()
        return !tags.includes(_tag)
    }

    const handleTagInput = (value) => { 
        value = value.map(function(x){ return x.toString().toUpperCase().replace(/[^a-zA-Z0-9_]/g,''); })     
        
        if (value === '')
            return false 
        
        if (tags.length !== value.length)
            setTags(value)
    }

    const setData = () => {
        setShowResult(false)
        setTags([])
        setNumber('')
        setOnlyScheduling(true)
        setTagBlock(false)
        setImportUnnamed(true)
        setGroupmanagement(true)
    }

    const handleSubmit = async(event) => {
        if (number === '') {
            ShowMessages({status : 500, message : "O número não foi informado!"})
            return
        }

        if (tags.length <= 0) {
            ShowMessages({status : 500, message : "Nenhuma tag foi informada!"})
            return
        }

        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-import'),document.querySelector('#submit-import').children[0].classList,document.querySelector('#submit-import').lastChild.nodeValue)
        try {

            const formData = new FormData();
            let _tags = tags.map(name => name.toUpperCase());
            
            formData.append('importUnnamed', importUnnamed ? 1 : 0);
            formData.append('groupmanagement', groupmanagement ? 1 : 0);
            formData.append('tagBlock', tagBlock ? 1 : 0);
            formData.append('onlyScheduling', onlyScheduling ? 1 : 0);
            formData.append('tags', _tags);
            
            await api.post('/api/v1/importWhatsApp/' + number.replace(/[\W_,[a-z]+/g,""), formData, {headers: { "Content-Type": "multipart/form-data"}})
                      .then(response => {
                         ShowMessages(response)
                         if (response.ok) {   
                             if (response.data.status === 200) {
                                setShowResult(true)     
                                setDataResult(response.data.report)
                             }
                        }
                        loadingButton(false,document.querySelector('#submit-import'),'fa fa-save','Importar')        
                       })
                       .catch( error => { 
                        console.log(error)   
                         loadingButton(false,document.querySelector('#submit-import'),'fa fa-save','Importar')    
                         ShowMessages(error) 
                       })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-import'),'fa fa-save','Importar')    
            ShowMessages(response)
        }
    }
    
    const getData = async() => {
        try {
            await api.get('/getDataCampaing')
                    .then(response => {
                        setDataNumbers(response.data.numbers) 
                        setDataTags(response.data.tags)    
                    })
                    .catch( error => {      
                        ShowMessages(error) 
                    })
        } catch (response) { 
            console.log(response) 
            ShowMessages(response)
        }
    }

    
    useEffect(() => {
        if (showModal)
            setData()
    },[showModal])
   
 
    useEffect(() => {
        getData()
        setStatistic('modals\\modal-import-whatsapp')
    } ,[])

    useEffect(() => {

    } ,[tags])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogClassName="width500" dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-google"></i> Propriedades de Importação do Google
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    {showResult ? 
                        <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                            <div style={{width:"450px", height:"250px", padding:"20px 15px", fontSize:"16px"}} className="box-shadow">
                                <div style={{display:"flex"}}  className="text-danger">
                                    <div style={{width:"340px"}}>
                                        <span style={{fontWeight:"bold"}}>Total de Registros Ignorados:</span>
                                    </div>
                                    <div style={{width:"75px", textAlign:"right"}}>
                                        {dataResult.ignored || 0}
                                    </div>
                                </div>
                                <div style={{display:"flex", marginTop:"15px"}}  className="text-success">
                                    <div style={{width:"340px"}}>
                                        <span style={{fontWeight:"bold"}}>Total de Registros Inseridos:</span>
                                    </div>
                                    <div style={{width:"75px", textAlign:"right"}}>
                                        {dataResult.inserted || 0}
                                    </div>
                                </div>
                                <div style={{display:"flex", marginTop:"15px"}}  className="text-warning">
                                    <div style={{width:"340px"}}>
                                        <span style={{fontWeight:"bold"}}>Total de Registros Alterados:</span>
                                    </div>
                                    <div style={{width:"75px", textAlign:"right"}}>
                                        {dataResult.updated || 0}
                                    </div>
                                </div>
                                <div style={{display:"flex", marginTop:"15px"}}  className="text-primary">
                                    <div style={{width:"340px"}}>
                                        <span style={{fontWeight:"bold"}}>Total de Grupos Inseridos|Alterados:</span>
                                    </div>
                                    <div style={{width:"75px", textAlign:"right"}}>
                                        {dataResult.groups || 0}
                                    </div>
                                </div>
                                <div style={{display:"flex", marginTop:"15px"}} className="text-info">
                                    <div style={{width:"340px"}}>
                                        <span style={{fontWeight:"bold"}} >Total de Registros Processados:</span>
                                    </div>
                                    <div style={{width:"75px", textAlign:"right"}}>
                                        {dataResult.total || 0}
                                    </div>
                                </div>
                            </div>
                        </div>
                    :
                        <div>               
                            <div style={{display:"flex"}}>
                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                    <input type="checkbox" checked={importUnnamed} value={importUnnamed} onClick={e => setImportUnnamed(!importUnnamed)}  />
                                    <span style={{ marginTop: "5px" }}></span>
                                </label>
                                <span style={{marginLeft:"5px",marginTop:"5px"}}>Importar contatos sem nome?</span>
                            </div>
                            <div style={{display:"flex"}}>
                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                    <input type="checkbox" checked={onlyScheduling} value={onlyScheduling} onClick={e => setOnlyScheduling(!onlyScheduling)}  />
                                    <span style={{ marginTop: "5px" }}></span>
                                </label>
                                <span style={{marginLeft:"5px",marginTop:"5px"}}>Importar somente contatos da agenda?</span>
                            </div>
                            <div style={{display:"flex"}}>
                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                    <input type="checkbox" checked={groupmanagement} value={groupmanagement} onClick={e => setGroupmanagement(!groupmanagement)}  />
                                    <span style={{ marginTop: "5px" }}></span>
                                </label>
                                <span style={{marginLeft:"5px",marginTop:"5px"}}>Importar grupos para a gestão de grupos?</span>
                            </div>
                            <div style={{display:"flex"}}>
                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                    <input type="checkbox" checked={tagBlock} value={tagBlock} onClick={e => setTagBlock(!tagBlock)}  />
                                    <span style={{ marginTop: "5px" }}></span>
                                </label>
                                <span style={{marginLeft:"5px",marginTop:"5px"}}>Importar como contato bloqueado?</span>
                            </div>
                            <div style={{marginTop:'15px'}}>
                                <span>Selecione o número da importação</span>
                                <select className="form-select" value={number} onChange={e => setNumber(e.target.value)}>
                                    <option value="">Selecione</option>
                                    {dataNumber.map((item) => {
                                        return(<option value={item.number}>{item.phone_format} - {item.description}</option>)
                                    })}
                                </select>
                            </div>
                            {!tagBlock ?
                                <div style={{display:"flex"}}>
                                    <div  style={{width:"100%"}}>
                                        <div style={{display:"flex", marginTop:"15px"}}>
                                            <div style={{width:"300px"}}>
                                                <label>Tags</label>
                                                <div className="input-group">
                                                    <TagsInput value={tags} onChange={e => handleTagInput(e)} beforeAddValidate={e => addValidationTag(e)}/>
                                                </div>
                                                <small className="text-danger">Após digitar a <span style={{fontWeight:"bold"}}>Tag</span>, aperte <span style={{fontWeight:"bold"}}>Enter</span></small>
                                            </div>     
                                    
                                            <div style={{marginLeft:"15px"}}>
                                                <button title="Click aqui para incluir uma tag já existente" type="button" className="btn btn-success" style={{marginTop:"15px"}} onClick={e => handleTagInput(addValidationTag(tag) ? [...tags,tag] : tags)}><i className="fa fa-arrow-left"></i></button>
                                            </div>       
                        
                                            <div style={{marginLeft:"15px"}}>
                                                <div style={{width:"200px"}}>
                                                    <span>Tags já existentes</span>
                                                    <select className="form-select" value={tag} onChange={e => setTag(e.target.value)} style={{minHeight:"37px"}}>
                                                        <option value="">Selecione</option>
                                                        {dataTags.map((item) => {
                                                            return(<option value={item}>{item}</option>)
                                                        })} 
                                                    </select>
                                                </div>
                                                <small className="text-danger">Selecione uma <span style={{fontWeight:"bold"}}>Tag</span> e click no botão ao lado para inserir</small>
                                            </div>                                    
                                        </div> 
                                    </div>
                                </div>       
                            : ""}
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>  
 
                    <Button type="button" id="submit-import" onClick={e => handleSubmit(e)}>
                        <em className="fa fa-save"></em> Importar
                    </Button>          
                    <Button type="button" className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}