import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { animated } from 'react-spring';
import Offcanvas from 'react-bootstrap/Offcanvas';
import api from '../../services/api';
import Loading from '../../components/Loading';
import {  ReactFlowProvider } from 'react-flow-renderer'
import ReactFlow, {
  addEdge,
  Background,
  Controls,
  MarkerType,
  Position,
  useEdgesState,
  useNodesState,
} from "reactflow";
import "reactflow/dist/style.css";

import ActionNode from "../../components/workflow/nodes/action-node";
import AudioNode from "../../components/workflow/nodes/audio-node";
import ButtonNode from "../../components/workflow/nodes/button-node";
import EndNode from "../../components/workflow/nodes/end-node";
import LocationNode from "../../components/workflow/nodes/location-node";
import FileNode from "../../components/workflow/nodes/file-node";
import ListNode from "../../components/workflow/nodes/list-node";
import MessageNode from "../../components/workflow/nodes/message-node";
import StartNode from "../../components/workflow/nodes/start-node";
import ContactNode from "../../components/workflow/nodes/contact-node";
import InputNode from "../../components/workflow/nodes/input-node";
import RedirectNode from "../../components/workflow/nodes/redirect-node";
import WebHookNode from "../../components/workflow/nodes/webhook-node";
import ConditionNode from "../../components/workflow/nodes/condition-node";
import RandomizeNode from "../../components/workflow/nodes/randomize-node";
import TriggerNode from "../../components/workflow/nodes/trigger-node";
import BridgeNode from "../../components/workflow/nodes/bridge-node";
import EmailNode from "../../components/workflow/nodes/email-node";
import WhatsAppNode from "../../components/workflow/nodes/whatsapp-node";
import IANode from "../../components/workflow/nodes/IA-node";
import Sidebar from "../../components/workflow/sidebar";

import { saveAs } from 'file-saver';

import ShowMessages from "../../components/Exceptions";
import CustomEdge from "../../components/workflow/edges/customEdge";

const initialNodes = [
    {
        id: '0',
        type: "startNode",
        data: {
            nodeInfo: {
            node             : 0,
            activeForMessage : 1,
            messagesActive   : [],
            isStart          : true,
            objectType       : 0,
            triggerType      : 0,
            targetId         : -1,
            commercial       : false,
            nonCommercial    : false,
            triggerContent   : 0,
            name             : 'WorkFlow',
            selectedTags     : [],
            number           : '',
            activateOption   : 0,
            day              : 0,
            hours            : 0,
            count            : 0,
            dayWeek          : '',
            groups           : [],
            eventGroup       : -1
          },
        },
        position: { x: -450, y: 5 },
    }
];

let id = 1;
const getId = () => `${id++}`;
const setId = (_id) => id = _id

const WorkflowChat = ({props}) => { 
    const [idautomationchat, setIdAutomationChat]   = useState(0)
    const [showMenu, setShowMenu]                   = useState(false)
    const [loading, setLoading]                     = useState(false)
    const [loadingSave, setLoadingSave]            = useState(false)

    const lastTargetNode                            = useRef(null);
    const reactFlowWrapper                          = useRef(null);
    const [nodes, setNodes, onNodesChange]          = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange]          = useEdgesState([]);

    const [reactFlowInstance, setReactFlowInstance] = useState(null);

    const proOptions = { hideAttribution: true };

    const nodeTypes = useMemo(
        () => ({
            startNode     : StartNode,
            messageNode   : MessageNode,
            buttonNode    : ButtonNode,
            endNode       : EndNode,
            actionNode    : ActionNode,
            listNode      : ListNode,
            audioNode     : AudioNode,
            fileNode      : FileNode,
            contactNode   : ContactNode,
            inputNode     : InputNode,
            redirectNode  : RedirectNode,
            webhookNode   : WebHookNode,
            conditionNode : ConditionNode,     
            randomizeNode : RandomizeNode,
            triggerNode   : TriggerNode, 
            bridgeNode    : BridgeNode ,
            emailNode     : EmailNode,
            whatsappNode  : WhatsAppNode ,
            IANode        : IANode,
            LocationNode  : LocationNode 
        }),
    []);

    const edgeTypes = useMemo(
        () => ({
            custom: CustomEdge,
        }),
        []
    );

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
    }, []);

    const onDrop = useCallback(
         (event) => {
          
            event.preventDefault();

            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            const type = event.dataTransfer.getData("application/reactflow");
            const nodeInfoData = event.dataTransfer.getData("nodeInfo");

            // check if the dropped element is valid
            if (typeof type === "undefined" || !type) {
                return;
            }
 
            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });

            const nodeId = getId();

            const nodeInfo = JSON.parse(nodeInfoData);
            nodeInfo.node  = parseInt(nodeId);
            nodeInfo.getId = getId

            const newNode = {
                id: nodeId,
                type,
                position,
                data: {
                    nodeInfo: nodeInfo,
                    toolbarPosition: Position.Right
                },
            };

            setNodes((nds) => nds.concat(newNode));
        },
        [reactFlowInstance, setNodes]
    );

    const onConnect = useCallback(
        (params) => {
            const edges = reactFlowInstance.getEdges();
            const isTheNodeItself = params.source === params.target;
      
            let _check = edges.filter(_item => {
                return parseInt(_item.source) === parseInt(params.source)
            })

            if ((_check.length > 0 ) && (params.sourceHandle.indexOf('button') < 0) && (params.sourceHandle.indexOf('randomize') < 0) && (params.sourceHandle.indexOf('action') < 0) && (params.sourceHandle.indexOf('condition') < 0)){
                ShowMessages({
                    status: 500,
                    message: "O elemento não pode ter duas saidas, faça uma ligação em serie!",
                });
                return;
            }

            if ((params.sourceHandle.indexOf('item') > 0) || 
                (params.sourceHandle.indexOf('button') > 0) ||
                (params.sourceHandle.indexOf('condition') > 0) || 
                (params.sourceHandle.indexOf('hook') > 0) ||
                (params.sourceHandle.indexOf('randomize') > 0) || 
                ((params.sourceHandle.indexOf('action') > 0))) {
                let _nodes = reactFlowInstance.getNodes()
    
                const _node = _nodes.find(
                    (edge) => parseInt(edge.id) === parseInt(params.source)
                )
          
                if (_node) {
                    let _list 
                    if (_node.data.nodeInfo.list)
                        _list = _node.data.nodeInfo.list.find(
                            (edge) => 'node-item' + edge.id === params.sourceHandle
                        )
                    else if (_node.data.nodeInfo.buttons) {
                        _list = _node.data.nodeInfo.buttons.find(
                            (edge) => 'node-button' + edge.id === params.sourceHandle
                        )
                    } else if (_node.data.nodeInfo.options) {
                        console.log(params.sourceHandle,_node.data.nodeInfo.options)
                        _list = _node.data.nodeInfo.options.find(
                            (edge) => 'node-condition' + edge.id === params.sourceHandle
                        )
                    } else if (params.sourceHandle.indexOf('action') > 0) {
                        if (params.sourceHandle === 'node-action6'){
                            _list = _node.data.nodeInfo.option.find(
                                (edge) => edge.id  === 6
                            )
                        } else 
                            _list = _node.data.nodeInfo
                    } else if (_node.data.nodeInfo.conditionData) {
                        _list = _node.data.nodeInfo.conditionData.find(
                            (edge) => 'node-hook' + edge.id === params.sourceHandle
                        )
                    } else if (_node.data.nodeInfo.percents) {
                        console.log(_node.data.nodeInfo.percents)
                        _list = _node.data.nodeInfo.percents.find(
                            (edge) => 'node-randomize' + edge.id === params.sourceHandle
                        )
                    }

                    _list['targetId'] = parseInt(params.target)        
                }
            }

            if (isTheNodeItself) {
                ShowMessages({
                    status: 500,
                    message: "Não é possivel conectar um nó a si mesmo!",
                });
                return;
            }

            setNodes((nodes) => {
                nodes.map((node) => {  
                    if ((parseInt(node.id) === parseInt(params.source)) && params.sourceHandle !== 'node-action6'){
                        node.data.nodeInfo = {
                            ...node.data.nodeInfo,
                            targetId: parseInt(params.target),
                        };
                    }

                    if (parseInt(node.id) === parseInt(params.target)) {
                        node.data.nodeInfo = {
                            ...node.data.nodeInfo,
                            parentId: parseInt(params.source),
                        };

                        lastTargetNode.current = params.target;
                    }

                    return node;
                });
                return nodes;
            });

            setEdges((eds) =>
                addEdge({
                    ...params,
                    type: "custom",
                    animated: true,
                    style: {
                        strokeWidth: 1,
                        stroke: "#cacaca",
                    },
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 10,
                        height: 10,
                        color: "#cacaca",
                    },
                }, eds)
            );
     },

    [setEdges, setNodes, reactFlowInstance]
    );

    const onNodesDelete = useCallback(
        (excludedNode) => {
            setNodes((nodes) => {
                nodes.map((node) => {
                    let parentId = parseInt(node.data.nodeInfo.parentId);
                    let targetId = parseInt(node.data.nodeInfo.targetId);

                    let _parentNode = nodes.filter(_item => {
                       return  parseInt(_item.id) === parentId
                    })

                    if (_parentNode.length > 0) {
                        [5,6,8,11].includes(_parentNode[0].data.nodeInfo.objectType)
                    }

                 //   if [].includes()
                    //SE BUTTON, ACTION, 
                    if (excludedNode[0].id === parentId) {
                        node.data.nodeInfo = {
                            ...node.data.nodeInfo,
                            parentId: -1,
                        };
                    }

                    if (excludedNode[0].id === targetId) {
                        node.data.nodeInfo = {
                            ...node.data.nodeInfo,
                            targetId: -1,
                        };
                    }

                    

                    return node;
                });
                return nodes;
            });
        },[setNodes]
    );

    const onSave = useCallback(async () => {
        const nodesArr = nodes;
        let error      = false; 
        let _nodeClose = false
        setLoadingSave(true)
        const nodeDataInfo = nodesArr.map((node) => {
            if (error)
                return node.data.nodeInfo

            let _targetId   = parseInt(node.data.nodeInfo.targetId)   || -1
            let _parentId   = parseInt(node.data.nodeInfo.parentId)  // || -1
            let _objectType = parseInt(node.data.nodeInfo.objectType) 

 
            if ((_objectType === 0) && (_targetId === -1)) {//NODE INICIAL
                ShowMessages({
                    status: 500,
                    message: `O nó #${node.data.nodeInfo.node} não está conectado a nenhum nó`,
                });
                error = true;
                return node.data.nodeInfo
            }

            if ([9,18].includes(_objectType) && (_parentId === -1)) {//NODE FINAL
                ShowMessages({
                    status: 500,
                    message: `O nó #${node.data.nodeInfo.node} não está conectado a nenhum nó`,
                });
                error = true;
                return node.data.nodeInfo
            }

            if ((_objectType === 8) && (node.data.nodeInfo.actionType === 1)) {//NODE FINAL
                console.log(node.data.nodeInfo)
                if (node.data.nodeInfo.option[0].targetId === -1) {
                    ShowMessages({
                        status: 500,
                        message: `Continuar a automação no nó #${node.data.nodeInfo.node} não está conectado a nenhum nó`,
                    });
                    error = true;
                    return node.data.nodeInfo
                }
                return node.data.nodeInfo
            }

            //INICIAL,FINAL,REDIRECT,TRIGGER,BRIDGE
            if ((![0,9,12,14,15,18].includes(_objectType)) && ((_targetId === -1) || (_parentId === -1))) {
                console.log(node.data.nodeInfo)
                ShowMessages({
                    status: 500,
                    message: `O nó #${node.data.nodeInfo.node} não está conectado a nenhum nó..`,
                });
                error = true;
                return node.data.nodeInfo
            }

            if ([11].includes(_objectType)) {
                let _check = node.data.nodeInfo.options.filter(_item => {
                    return _item.targetId === -1
                })

                if (_check.length > 0) {
                    ShowMessages({
                       status: 500,
                       message: `Um ou mais itens do #${node.data.nodeInfo.node} não está conectado a nenhum nó, exclua todas as conexões deste nó e vincule novamente!`,
                    });
                    error = true;
                }
             
                return node.data.nodeInfo
            }

            //FINAL, REDIRECT, TRIGGER, BRIDGE, IA
            if ([9,12,14,15,18].includes(_objectType))
                _nodeClose = true


            if (_objectType === 5) {
                let _buttons = node.data.nodeInfo.buttons
                _buttons = _buttons.filter(_item => {
                    return _item.targetId === undefined
                })

                if (_buttons.length > 0) {
                    ShowMessages({
                        status: 500,
                        message: `O botão #${node.data.nodeInfo.node} tem alguma opção sem conexão, por favor, conecte todas!`,
                    }); 
                    error = true;
                    return node.data.nodeInfo
                }
            }
            return node.data.nodeInfo;
        });

        try {
            if (error) {
                setLoadingSave(false)
                return 
            }

            if (!_nodeClose) {
                ShowMessages({
                    status: 500,
                    message: `Nenhum nó de encerramento de fluxo foi informado.`,
                });
                setLoadingSave(false)
                return
            }

            await api
                .post("/chatbot", { idautomationchat, idmynumbertype : props.type, flux: {"data" : nodeDataInfo}, flow : reactFlowInstance.toObject()})
                .then((response) => {
                    setIdAutomationChat(response.data.id)
                    ShowMessages(response.data)
                    setShowMenu(false)
                    setLoadingSave(false)
                })
                .catch((error) => {
                    setLoadingSave(false)
                ShowMessages(error);
                });
        } catch (response) {
            setLoadingSave(false)
            ShowMessages(response);
        }

    }, [nodes, reactFlowInstance]);

    const onRestore = async(id) => {
        setLoading(true)
        await api.get("/chatbot/" + id)
                 .then((response) => {
                    let flow      = response.data.automation.flow;
                    let nodes     = flow.nodes
                    let statistic = response.data.statistic;
      
                    statistic.map(_item => {
                        let _node = nodes.find(_node => {
                            return (parseInt(_node.id) === parseInt(_item.node))
                        })
                        if (_node) 
                            _node.data.nodeInfo.statistic = _item.total
                    })
                    
                    if (flow) {
                        setNodes(nodes || []);
                        setEdges(flow.edges || []);

                        setId(flow.nodes.reduce((max, obj) => Math.max(max, obj.id), -Infinity) + 1)
                    }
                    setLoading(false)
                  })
                  .catch((error) => {
                      ShowMessages(error);
                  });
    };

    const ExportFile = async() => {
        await api.get("/chatbot/" + props?.id)
                 .then((response) => {
                    const flow = response.data.automation.flow;
                    const flux = response.data.automation.flux;
                    const number = response.data.automation.number
                    const title = response.data.automation.title
                    const webhook = response.data.automation.webhook
                    const webhookFields = response.data.automation.webhook_fields

                    const fileexport = {
                        flow,
                        flux,
                        number,
                        title,
                        webhook,
                        webhookFields
                    }

                    const jsonString = JSON.stringify(fileexport, null, 2);
                    const blob = new Blob([jsonString], { type: 'application/json' });

                    saveAs(blob, '55Zap_Fluxo_' + response.data.automation.title + '.55zap');
        })
        .catch((error) => {
            ShowMessages(error);
        }); 
    }

    const ImportFile = async(file) => {
        console.log(file)
        if (file) {
            // Crie um objeto FileReader para ler o arquivo
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                  const content  = e.target.result;
                  const jsonData = JSON.parse(content);
                  const flow     = jsonData.flow;

                  if (flow) {
                      setNodes(flow.nodes || []);
                      setEdges(flow.edges || []);
                  }  
                  setId(flow.nodes.reduce((max, obj) => Math.max(max, obj.id), -Infinity) + 1)
                } catch (error) {
                  console.error('Erro ao analisar o arquivo JSON:', error);
                }
              };
        
              // Leia o arquivo como texto
              reader.readAsText(file);
        }
        setLoading(true)
        await api.get("/chatbot/" + id)
                 .then((response) => {
                    const flow = response.data.flow;
                    if (flow) {
                      setNodes(flow.nodes || []);
                      setEdges(flow.edges || []);
  
                      setId(flow.nodes.reduce((max, obj) => Math.max(max, obj.id), -Infinity) + 1)
                    }
                    setLoading(false)
                  })
                  .catch((error) => {
                      ShowMessages(error);
                  });
      
    }

    const handleShowMenu = () => { setShowMenu(false)}

    useEffect(() => {
        if (props?.id) {
            setIdAutomationChat(props?.id)
            onRestore(props?.id)
        }
        window.getId   = getId
    },[props])

    const handleBlur = (e) => {
        console.log(e)
    }
    return (
        <div className="dndflow" style={{ position: "relative" }}>
            {loading && (
                <Loading loading={loading}/>
            )}
            <input type="file" accept=".55zap"  onChange={e => ImportFile(e.target.files[0])} className="hidden" id="importFile" aria-describedby="importFile" aria-label="Upload"/>
            {!loading && (
                <ReactFlowProvider nodeRenderStep={0}>

                    <div>
                        <Offcanvas name="menu-option" show={showMenu} onHide={handleShowMenu} placement="end" backdrop={false} style={{width:"360px"}}>
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>Opções do Fluxo</Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body style={{overflow:"hidden"}}>
                                <Sidebar handleShowMenu={handleShowMenu} ExportFile={ExportFile} onSave={onSave} type={props.type} loading={loadingSave}/>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </div>
                    <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                        <animated.div style={{ position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }}>
                            <ReactFlow
                                nodes={nodes}
                                edges={edges}
                                onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                onNodesDelete={onNodesDelete}
                                onConnect={onConnect}
                                snapToGrid={true}
                                nodeTypes={nodeTypes}
                                attributionPosition="top-right"
                                fitView
                                onInit={setReactFlowInstance}
                                onDrop={onDrop}
                                onDragOver={onDragOver}
                                proOptions={proOptions}
                                edgeTypes={edgeTypes}>
                                <Controls />
                                <Background variant="line" />
                            </ReactFlow>
                        </animated.div>
                    </div>
                </ReactFlowProvider>
            )}
            <div style={{ position: "absolute", top: "0px", right: "0px", padding:"5px", display:"flex", gap:"5px" }}>
                <button className="btn btn-primary" type="button" onClick={e => setShowMenu(true)}>
                    <i className="fa fa-list"/>
                </button>
            </div>
        </div>
    );
};

export default WorkflowChat;
